import React, { useCallback } from "react"
import { View, Text, StyleSheet, FlatList, Pressable } from "react-native"
import { MateWithMovies } from "../../database";
import Poster from "./Poster";
import Counter from "../../common/Counter";
import { Color, GlobalStyle } from "../../styles";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import { useNavigation } from "@react-navigation/native";
import EmptyListElement from "./EmptyListElement";
import ScreenNavigator from "../../navigation";
import { Icon } from "react-native-elements";

export default function MateListItem({ mate }: { mate: MateWithMovies }) {
    const navigation = useNavigation();

    const renderItem = useCallback(({ item: movie }) => (
        <Poster movie={movie} />
    ), [])

    const onPress = useCallback(() => {
        ScreenNavigator.navigateToMateMovies(navigation, mate.mate_id)
    }, [navigation, mate])

    const renderEmptyRow = useCallback(() => (
        <View style={styles.emptyRowContainer}>
            <Icon name={GlobalStyle.ICON_MATCHES_FA} type="font-awesome" color={Color.textSecondaryColor}/>
            <Text style={styles.emptyRowText}>There are no shared matches yet. Swipe more!</Text>
        </View>
    ), [])

    const renderRow = useCallback(() => (
        <View style={styles.container}>
            <FlatList
                style={styles.movies}
                ListEmptyComponent={EmptyListElement}
                data={mate.movies}
                renderItem={renderItem}
                keyExtractor={item => item.id}
                initialNumToRender={1}
                maxToRenderPerBatch={1}
                updateCellsBatchingPeriod={500}
                horizontal={true}
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
            />
            <View style={styles.counter}>
                <Counter icon="film" count={mate.movies ? mate.movies.length : 0} />
            </View>
        </View>
    ), [mate, renderItem])

    return (
        <TouchableWithoutFeedback style={styles.container} onPress={onPress}>
            { (mate.movies && mate.movies.length > 0) ? renderRow() : renderEmptyRow() }
            <View style={styles.mateContainer}>
                <Text style={styles.mateName}>{mate.mate_username}</Text>
            </View>
        </TouchableWithoutFeedback>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%'
    },
    movies: {
        width: '100%'
    },
    mateContainer: {
        position: 'absolute',
        bottom: 12,
        left: 16
    },
    mateName: {
        color: Color.textPrimaryColor,
        fontSize: 32,
        fontWeight: 'bold'
    },
    emptyRowContainer: {
        flexDirection: "row",
        width: '100%',
        justifyContent: "center",
        alignItems: "center",
        height: GlobalStyle.posterHeightCard,
    },
    emptyRowText: {
        color: Color.textSecondaryColor,
        margin: 12
    },
    counter: {
        position: 'absolute',
        right: 16,
        top: 8,
    },
});