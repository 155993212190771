import { useState, useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { Movie, MovieCountMatch, database } from '../database';
import MoviesPage from '../common/MoviesPage';
import { GlobalStyle } from '../styles';
import { Platform } from 'react-native';

function UserMoviesScreen() {
  const [movies, setMovies] = useState<MovieCountMatch[]>([])

  const fetchUserMatches = useCallback(async () => {
    database.fetchMatches(true).then(setMovies)
  }, [setMovies])

  useFocusEffect(
    useCallback(() => {
      fetchUserMatches();
    }, []),
  );

  return <MoviesPage movies={movies} containerStyle={{ paddingTop: Platform.OS != 'web' ? GlobalStyle.SafeAreaPaddingTop : 0}}/>
}

export default UserMoviesScreen;