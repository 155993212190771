import * as React from "react";
import {
    View,
    Text,
    Image,
    StyleSheet
} from "react-native";
import { Color } from "../../styles";
import { TmdbMovie } from "../../tmdb";
import { toHoursAndMinutes } from "../../utils";

export function MovieDetails(params: {movie: TmdbMovie}) {
    const { movie } = params;
    const NUMBER_OF_GENRES = 2;

    return (
        <View style={localStyles.container}>
            { movie.release_date && 
                <View style={localStyles.container}>
                    <Text style={localStyles.text}>
                        {new Date(movie.release_date).getFullYear()}
                    </Text>
                    <View style={localStyles.icon}/>
                </View>
            }
            <Text style={localStyles.text}>
                {movie.genres.slice(0, Math.min(NUMBER_OF_GENRES, movie.genres.length)).map(genre => genre.name).join(", ")}
            </Text>
            <View style={localStyles.icon}/>
            <Text style={localStyles.text}>
                {toHoursAndMinutes(movie.runtime)}
            </Text>
        </View>
    );
}

const localStyles = StyleSheet.create({
    container: {
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
    },
    icon: {
        width: 4,
        height: 4,
        marginHorizontal: 8,
        borderRadius: 2,
        backgroundColor: Color.textSecondaryColor
    },
    text: {
        color: Color.textSecondaryColor,
        fontSize: 16,
        textAlign: "center",
    }
});