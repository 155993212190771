import React from "react"
import { StyleSheet, View } from 'react-native';
import BottomBar from './components/BottomBar';
import { DefaultTheme, NavigationContainer, createNavigationContainerRef } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import DiscoverScreen from './components/discover-screen/DiscoverScreen';
import UserMoviesScreen from './components/movies-screen/UserMoviesScreen';
import FriendsScreen from './components/friends-screen/FriendsScreen';
import PopularScreen from './components/popular-screen/PopularScreen';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import SessionWrapper from './components/auth/SessionWrapper';
import MovieDetailsScreen from './components/movie-details-screen/MovieDetailsScreen';
import InvitationSummaryScreen from './components/invitation-screen/InvitationSummaryScreen'
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { Color, GlobalStyle } from "./components/styles";
import SettingsScreen from "./components/settings-screen/SettingsScreen";
import { ScreenNames } from "./components/navigation";
import MateMoviesScreen from "./components/movies-screen/MateMoviesScreen";
import GroupMoviesScreen from "./components/movies-screen/GroupMoviesScreen";
import { PublicInvitationScreem } from "./components/invitation-screen/PublicInvitationScreen";
import { supabase } from "./components/supabase";
import Auth from "./components/auth/Auth";

const Stack = createNativeStackNavigator();

const Tab = createBottomTabNavigator();

const navigationRef = createNavigationContainerRef()

const WatchMateTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: Color.backgroundColor,
  },
};

export default function App() {

  const linking = {
    prefixes: ['https://watchmate.live', 'watchmate://'],
    config: {
      screens: {
        "Discover": {
          path: ScreenNames.DISCOVER.path,
        },
        "Mates": {
          path: ScreenNames.MATES.path,
        },
        "Popular": {
          path: ScreenNames.POPULAR.path,
        },
        "InvitationSummary": {
          path: ScreenNames.INVITE.path,
        },
        "MovieDetails": {
          path: ScreenNames.MOVIES_DETAILS.path,
        },
        "Matches": {
          path: ScreenNames.MATCHES.path,
        },
        "MateMovies": {
          path: ScreenNames.MATE_MOVIES.path,
        },
        "GroupMovies": {
          path: ScreenNames.GROUP_MOVIES.path,
        },
        "Settings": {
          path: ScreenNames.SETTINGS.path,
        },
      },
    },
  };

  const unauthenticatedLinking = {
    prefixes: ['https://watchmate.live', 'watchmate://'],
    config: {
      screens: {
        "Authentication": {
          path: ScreenNames.AUTH.path,
        },
        "InvitationSummary": {
          path: ScreenNames.INVITE.path,
        },
      },
    },
  };

  const unathenticated = () => (
    <NavigationContainer theme={WatchMateTheme} linking={unauthenticatedLinking}>
      <Stack.Navigator
            initialRouteName={ScreenNames.AUTH.name}
            screenOptions={{ headerShown: false }}>
        <Stack.Screen name={ScreenNames.AUTH.name} component={Auth}/>
        <Stack.Screen name={ScreenNames.INVITE.name}  component={PublicInvitationScreem}/>
      </Stack.Navigator>
    </NavigationContainer>
  )

  return (
    <GestureHandlerRootView style={styles.root}>
      <SessionWrapper unathenticated={unathenticated()} navigationRef={navigationRef}>
        <NavigationContainer theme={WatchMateTheme} linking={linking} ref={navigationRef}>
          <Tab.Navigator
            initialRouteName={ScreenNames.DISCOVER.name}
            sceneContainerStyle={styles.scene}
            backBehavior="history"
            screenOptions={{ headerShown: false }}
            tabBar={(props) => <BottomBar {...props} />} >
            {/* Bottom bar screens */}
            <Tab.Screen name={ScreenNames.DISCOVER.name} component={DiscoverScreen} />
            <Tab.Screen name={ScreenNames.MATCHES.name} component={UserMoviesScreen} />
            <Tab.Screen name={ScreenNames.MATES.name} component={FriendsScreen} />
            <Tab.Screen name={ScreenNames.POPULAR.name} component={PopularScreen} />
            {/* Additional screens */}
            <Tab.Screen name={ScreenNames.MATE_MOVIES.name} component={MateMoviesScreen} options={{ tabBarStyle: { display: "none" } }} />
            <Tab.Screen name={ScreenNames.GROUP_MOVIES.name} component={GroupMoviesScreen} options={{ tabBarStyle: { display: "none" } }} />
            <Tab.Screen name={ScreenNames.MOVIES_DETAILS.name} component={MovieDetailsScreen} options={{ tabBarStyle: { display: "none" } }} />
            <Tab.Screen name={ScreenNames.INVITE.name} component={InvitationSummaryScreen} options={{ tabBarStyle: { display: "none" } }} />
            <Tab.Screen name={ScreenNames.SETTINGS.name} component={SettingsScreen} options={{ tabBarStyle: { display: "none" } }} />
          </Tab.Navigator>
        </NavigationContainer>
      </SessionWrapper >
    </GestureHandlerRootView>
  )
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: Color.backgroundColor
  },
  scene: {
    maxWidth: GlobalStyle.maxWidth,
    width: "100%",
    alignSelf: "center",
  }
});
