import { PostgrestError } from "@supabase/supabase-js"
import { LocalStorage } from "./localStorage"
import { supabase } from "./supabase"
import { arrayIntersection } from "./utils"

function logError(error) {
    if (error) {
        console.error('DB error', error)
    }
}

async function cachedQuery<T>(key: string, query: () => Promise<{ data: T[] | null, error: PostgrestError | null }>): Promise<T | null> {
    const cacheResult = await LocalStorage.get<T>(key)
    if (cacheResult) {
        return cacheResult;
    }
    const { data, error } = await query()
    logError(error)
    if (data == null || data.length == 0) {
        return null;    
    }
    const result = data[0]
    LocalStorage.store(key, result)
    return result
}

export const database = {

    deleteMatch: async function(movieId: string) {
    await supabase.from('watch_match')
        .update({match: false})
        .eq('watchable_id', movieId)
    },

    leaveGroup: async function(groupId: string) {
        const { error } = await supabase.rpc('leave_group_by_id', { _group_id: groupId })
        logError(error)
    },

    removeMate: async function(mateId: string) {
        const { error } = await supabase.rpc('delete_mate_by_id', { _mate_id: mateId })
        logError(error)
    },

    createMatch: async function(movieId: string, liked: boolean) {
        const { error } = await supabase
            .from('watch_match')
            .upsert({ watchable_id: movieId, match: liked })
        logError(error)
    },

    acceptInvite: async function(requestingUserId: string) {

        console.log('Accepting invitation from:', requestingUserId)

        const session = await supabase.auth.getSession()

        // TODO handle errors
        // FIXME upsert without primary key creates duplicates!
        const requestedUserId = session.data.session.user.id;
        const { error, data } = await supabase
            .from('mates')
            .upsert({
                requesting_user_id: requestingUserId,
                requested_user_id: requestedUserId
            })
            .select()

        logError(error)
    },

    fetchMyProfile: async function(): Promise<Profile> {
        const session = await supabase.auth.getSession()
        const me = session.data.session.user.id;
        const { data, error } = await supabase.from('profiles')
            .select()
            .eq("id", me)
        logError(error)
        return data[0]
    },

    updateMyUsername: async function(username: string): Promise<Profile> {
        const session = await supabase.auth.getSession()
        const me = session.data.session.user.id;
        const { data, error } = await supabase.from('profiles').upsert({
                id: me,
                username: username,
                updated_at: new Date(),
            }).select()
        logError(error)
        return data[0]
    },

    fetchProfile: async function(id: string) {
        const { data, error } = await supabase.from('profiles')
            .select()
            .eq("id", id)
        logError(error)
        return data[0]
    },

    fetchWatchable: async function(id: string): Promise<Movie | null> {
        // this query can be cached, because watchable object does not change as much as profile, match, mates 
        return cachedQuery<Movie>("watchable-id-" + id, async () => {
            return await supabase.from('watchable')
                .select()
                .eq("id", id)
        })
    },

    fetchWatchableByTmdbId: async function(tmdbId: string): Promise<Movie> {
        const { data, error } = await supabase.from('watchable')
            .select()
            .eq("tmdb_id", tmdbId)
        logError(error)
        return data[0]
    },

    fetchNewWatchables: async function(rows: number, skip: number): Promise<Movie[]> {
        const { data, error } = await supabase.rpc('get_new_watchables', { rows: rows + skip, skip: skip })
        logError(error)
        return data;
    },

    fetchPopularWatchables: async function(days: number, limit: number): Promise<MovieCountMatch[]> {
        const { data, error } = await supabase.rpc('popular_matches', { _days: days, _limit: limit })
        logError(error)
        return (data as (Movie & {count: number, match: boolean})[]).map(movie => ({ movie: movie, count: movie.count, match: movie.match}));
    },

    fetchMatches: async function(liked: boolean): Promise<MovieCountMatch[]> {
        const session = await supabase.auth.getSession()
        const currentUserId = session.data.session.user.id;
        const { data, error } = await supabase
            .from('watch_match')
            .select(`
                watchable_id,
                watchable (
                    id,
                    tmdb_id,
                    title,
                    poster_path,
                    backdrop_path,
                    overview,
                    watch_match (
                        id,
                        match,
                        profiles (
                            id, 
                            username
                        )
                    )
                )
            `)
            .eq("match", liked)
            .eq("user_id", currentUserId)
            .eq("watchable.watch_match.match", liked)

        logError(error)
        if (data) {
            const result = data.map(item => ({ 
                movie: item.watchable as unknown as Movie, 
                count: item.watchable.watch_match.filter(match => match.profiles.id != currentUserId).length as number, 
                match: liked
            }));
            return result;
        } 
        return [];
    },

    fetchMates: async function(): Promise<Array<MateWithMovies>> {
        const { data, error } = await supabase.rpc("get_matching_mates_v3")
        logError(error)
        data.forEach((mate: MateWithMovies) => {
            LocalStorage.store("mate-id-" + mate.mate_id, mate)
        });
        return data
    },

    fetchMatesMatchesCount: async function (watchableIds: string[]): Promise<MovieCountMatchRaw[]> {
        const { data, error } = await supabase.rpc('get_mates_match_count_on', { _ids: watchableIds })
        logError(error)
        return data;
    },

    fetchMate: async function(id: string): Promise<MateWithMovies | null> {
        return LocalStorage.get<MateWithMovies>("mate-id-" + id)
    },
    
    fetchGroups: async function(): Promise<GroupWithMovies[]> {
        const { data, error } = await supabase.from('groups')
            .select(`
                id,
                name,
                group_mates (
                    profiles (
                        id,
                        username,
                        watch_match (
                            match,
                            watchable (
                                id,
                                tmdb_id,
                                poster_path,
                                title,
                                backdrop_path,
                                overview
                            )
                        )
                    )
                )
            `)
            .eq("group_mates.profiles.watch_match.match", true)
    
        if (error) {
            logError(error)
            return [];
        }
    
        return data.map(group => {
            const arrayOfMovies: Movie[][] = group.group_mates
                .map(mate => mate.profiles.watch_match
                    .flatMap(match => match.watchable));
            const movies = arrayIntersection(arrayOfMovies)
            const result = {
                group_id: group.id,
                group_name: group.name,
                group_mates: group.group_mates.map(mate => ({ mate_id: mate.profiles.id, mate_username: mate.profiles.username })),
                movies: movies as Movie[]
            }
            LocalStorage.store("group-id-" + result.group_id, result)
            return result;
        })
    },

    fetchGroup: async function(id: string): Promise<GroupWithMovies | null> {
        return LocalStorage.get<GroupWithMovies>("group-id-" + id)
    },
    
    createGroup: async function(name: string, mates: string[]): Promise<string> {
        const { data: groupId, error } = await supabase.rpc('create_group', { group_name: name })
        console.log(`Created group with id=${groupId}`)
        logError(error)
        if (!error) {
            const { data: matesData, error: matesError } = await supabase
                .from('group_mates')
                .upsert(mates.map(mate => ({ user_id: mate, group_id: groupId })))
                .select();
            logError(matesError)    
        }
        return groupId;
    },

    fetchWatchableMatchMates: async function(id: string, liked: boolean): Promise<Mate[]> {
        const session = await supabase.auth.getSession()
        const currentUserId = session.data.session.user.id;
        const { data, error } = await supabase
            .from('watch_match')
            .select(`
                watchable_id,
                match,
                profiles (
                    id, 
                    username
                )
            `)
            .eq("match", liked)
            .neq("user_id", currentUserId)
            .eq("watchable_id", id)
        logError(error)
        if (data) {
            const result = data.map(w => w.profiles as any as Mate);
            // supabase is messing with return type, it's right type
            return result;
        }
        return [];
    }
}

export type Profile = {
    id: string,
    username?: string | undefined,
    website?: string | undefined
}

export type Movie = {
    id: string;
    tmdb_id: string;
    title: string;
    overview: string;
    poster_path: string;
    backdrop_path: string;
};

export type MovieCountMatch = {
    movie: Movie;
    count: number;
    match: boolean;
}

export type MovieCountMatchRaw = {
    watchable_id: string;
    count: number;
}

export interface MateWithMovies {
    mate_id: string,
    mate_username: string,
    movies: Array<Movie> | null
}

export interface GroupWithMovies {
    group_id: string,
    group_name: string,
    group_mates: GroupMate[],
    movies: Movie[]
}

export interface GroupMate {
    mate_id: string,
    mate_username: string
}

export interface Mate {
  id: string
  username: string
}