import { useEffect, useState } from 'react';
import { StyleSheet, Image, View } from 'react-native';
import { getImageUrl, getCast } from '../tmdb';

function MovieCast(props) {
  const CAST_POSTERS = 4;
  const { tmdb_id } = props;
  const [cast, setCast] = useState(null)

  const fetchCast = async () => {
    const cast = await getCast(tmdb_id)
    setCast([...cast])
  }

  useEffect(() => {
    fetchCast();
  }, [tmdb_id])

  return (
    <View style={styles.container} >
      {cast && cast.slice(0, CAST_POSTERS).map((person) => {
        return (
          <Image key={person.id} style={styles.image} source={{ uri: getImageUrl(person.profile_path) }}/>
        )
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 10
  },
  image: {
    width: 75,
    height: 110,
    borderRadius: 10,
  }
});

export default MovieCast;