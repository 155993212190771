import { Session } from "@supabase/supabase-js"
import { ReactNode, useEffect, useState } from "react"
import { supabase } from "../supabase"
import Auth from "./Auth"
import { NavigationContainerRefWithCurrent } from "@react-navigation/native"
import ScreenNavigator from "../navigation"

function SessionWrapper({children, unathenticated, navigationRef} 
    : { children: ReactNode, unathenticated: ReactNode, navigationRef: NavigationContainerRefWithCurrent<ReactNavigation.RootParamList>}) {
    const [session, setSession] = useState<Session | null>(null)

    const redirectPath = new URLSearchParams(window?.location?.search || {}).get('redirectPath');
    console.debug("redirectPath", redirectPath)

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
        setSession(session)
        })

        supabase.auth.onAuthStateChange((_event, session) => {
        setSession(session)
        })
    }, [])

    useEffect(() => {
        if (navigationRef && session && redirectPath) {
            ScreenNavigator.redirectTo(navigationRef, redirectPath)
        } 
    }, [navigationRef, session, redirectPath])

    return (
        session && session.user ? 
        children :
        unathenticated
    )
}

export default SessionWrapper;