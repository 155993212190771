import { Button, StyleSheet, Text, View } from 'react-native';
import { supabase } from "../supabase"
import { useCallback, useEffect } from 'react';
import Account from '../auth/Account';
import { useState } from "react";
import { ActionBar } from '../common/ActionBar';

function SettingsScreen() {
  const [session, setSession] = useState();

  useEffect(() => {
    async function getSession() {
      const { data, error } = await supabase.auth.getSession()
      setSession(data.session)
    }
    getSession();
  }, [])

  return (
    <View style={styles.container} >
      <ActionBar/>
      {session != null ?
        <Account session={session}/>
      : <></>
      }
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  }
});

export default SettingsScreen;