import {
    FlatList,
    View,
    Text,
    StyleSheet
} from "react-native";
import { CastMember } from "./CastMember";
import { Header } from "./Header";
import { TmdbCast, TmdbImage, TmdbMovie, getCast } from "../../tmdb";
import { useCallback, useEffect, useState } from "react";

export function CastList(params: {movie: TmdbMovie, maxSize: number, showNames: boolean}) {
    const { movie, maxSize, showNames } = params;
    const [cast, setCast] = useState<TmdbCast[]>([])

    const fetchCast = async (id: string) => {
      const cast = await getCast(id)
      const filteredCast = cast
        .filter(member => member.profile_path != null)
        .slice(0, maxSize != 0 ? Math.min(maxSize, cast.length) : cast.length)
      setCast([...filteredCast])
    }

    useEffect(() => {
        fetchCast(movie.id);
    }, [movie])

    const renderItem = useCallback(({item: member}) => (
        <CastMember member={member} showName={showNames}/>
    ), [])

    return (
        <View style={localStyles.container}>
            <Header name={"Cast"}/>
            <View style={localStyles.innerContainer}>
                <FlatList
                    data={cast}
                    keyExtractor={cast => cast.id}
                    initialNumToRender={1}
                    maxToRenderPerBatch={1}
                    updateCellsBatchingPeriod={500}
                    renderItem={renderItem}
                    horizontal={true}
                    showsVerticalScrollIndicator={false}
                    showsHorizontalScrollIndicator={false}
                />
            </View>
        </View>
    );
}

const localStyles = StyleSheet.create({
    container: {
        width: "100%",
    },
    innerContainer: {
        width: "100%",
    }
});