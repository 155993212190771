import { useCallback, useEffect, useState } from "react";
import { Movie, database } from "../database";
import { Dimensions, FlatList, ImageBackground, StyleSheet, useWindowDimensions } from "react-native";
import { getScreenHeight, getScreenWidth } from "../utils";
import { getImageUrl } from "../tmdb";

const COLUMNS = 3;
const ROWS = 6;
const TIMER_MS = 800;

export function MoviesBackground(params: { maxWidth?: number }) {
    const { width } = useWindowDimensions();
    const [movies, setMovies] = useState<Movie[]>([])
    const [randomNumber, setRandomNumber] = useState(ROWS)
    const [maxWidth, setMaxWidth] = useState(-1)

    useEffect(() => {
        if (params.maxWidth && params.maxWidth > 0) {
            setMaxWidth(params.maxWidth)
        }
    }, [params]);

    useEffect(() => {
        database.fetchNewWatchables(COLUMNS * ROWS, 0).then(result => setMovies(result));
        const interval = setInterval(() => {
            setRandomNumber(Math.floor(Math.random() * ROWS) + 1)
        }, TIMER_MS);
    }, [])

    const renderItem = useCallback(({ item, index }: { item: Movie, index: number }) => (
        <ImageBackground
            style={[styles.image, { width: maxWidth > 0 ? Math.min(width, maxWidth) / COLUMNS : width / COLUMNS }]}
            blurRadius={((index % (ROWS - 1)) === randomNumber) ? 0 : 10}
            source={{ uri: getImageUrl(item.poster_path) }}
            resizeMode="cover"
        />
    ), [randomNumber, width])

    return (
        <FlatList
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            style={styles.imagesContainer}
            data={movies}
            numColumns={COLUMNS}
            keyExtractor={movie => movie.id}
            renderItem={renderItem} />
    )
}

const styles = StyleSheet.create({
    imagesContainer: {
        height: "100%",
        width: '100%',
        opacity: 100,
    },
    image: {
        minHeight: 200,
    }
})