import { Pressable, SafeAreaView, StyleSheet, View, FlatList, Text, ViewStyle, Keyboard, Platform } from 'react-native';
import React, { Ref, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Mate, MateWithMovies, database } from '../database';
import { BottomSheetFlatList } from "@gorhom/bottom-sheet";
import { Button, Icon, Input } from 'react-native-elements';
import { Color, GlobalStyle } from '../styles';
import { BlurView } from 'expo-blur';
import { getScreenWidth } from '../utils';
import { BottomSheetItem, BottomSheetItemText, BottomSheetHeader, BottomSheetHeaderTitle, BottomSheetContainer, BottomSheet, BottomSheetStyleValues } from './bottomSheet';

function MateListBottomSheet(
  { mates, sheetRef } 
  : { mates: Mate[], sheetRef: Ref<BottomSheet>}) {
  const snapPoints = useMemo(() => ["33%", "66%"], []);

  const renderItem = useCallback(
    ({ item, index }: { item: Mate, index: number}) => (
      <BottomSheetItem index={index} size={mates.length}>
        <BottomSheetItemText index={index} size={mates.length} value={item.username} style={styles.itemText}/>
      </BottomSheetItem>
    ),
    [mates]
  );

  const renderHeader = useCallback(() => (
    <BottomSheetHeader style={styles.header}>
      <BottomSheetHeaderTitle value={"Mates"}/>
    </BottomSheetHeader>
  ), []);

  return (
      <BottomSheet sheetRef={sheetRef} snapPoints={snapPoints} header={renderHeader()}>
        <BottomSheetFlatList
          keyExtractor={(i) => i.id}
          renderItem={renderItem} 
          data={mates}          
        />
      </BottomSheet>
  )
}

const styles = StyleSheet.create({
  container: {

  },
  header: {
    justifyContent: 'center'
  },
  itemText: {
    marginStart: BottomSheetStyleValues.HORIZONTAL_MARGIN
  }
});

export default MateListBottomSheet;