import * as React from "react";
import {
    ScrollView, View,
    Text,
    Image,
    StyleSheet,
    FlatList
} from "react-native";
import { Header } from "./Header";
import { TmdbImage, TmdbMovie, getImageUrl, TMDBApi } from "../../tmdb";
import { useCallback, useEffect, useState } from "react";

export function PhotoList(params: { movie: TmdbMovie }) {
    const { movie } = params;
    const [images, setImages] = useState<TmdbImage[]>([])

    useEffect(() => {
        TMDBApi.getMovieImages(movie.id).then(data => {
            const filteredImages = data.backdrops.slice(0, Math.min(6, data.backdrops.length));
            setImages(filteredImages)}
        )
    }, [movie])

    const renderItem = useCallback(({item: image}) => (
        PhotoCoponent(image.file_path)
    ), [])

    if (images.length == 0) {
        return <></>
    }

    return (
        <View style={localStyles.container}>
            <Header name={"Photo"}/>
            <FlatList
                data={images}
                keyExtractor={item => item.file_path}
                initialNumToRender={1}
                maxToRenderPerBatch={1}
                updateCellsBatchingPeriod={500}
                renderItem={renderItem}
                horizontal={true}
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                contentContainerStyle={localStyles.innerContainer}
            />
        </View>
    );
}

const localStyles = StyleSheet.create({
    container: {
        width: "100%",
    },
    innerContainer: {
        gap: 8
    },
    image: {
        height: 200,
        width: 300,
    }
});

function PhotoCoponent(photoUri: string) {
    return (
        <Image key={photoUri}
            style={localStyles.image}
            source={{ uri: getImageUrl(photoUri) }} 
        />
    );
}
