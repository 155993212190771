import { LayoutAnimation, Text, StyleSheet, TouchableOpacity, View, StyleProp, ViewStyle } from 'react-native';
import React, { useEffect, useState } from "react"
import ScreenNavigator, { ScreenNames } from './navigation';
import { Icon } from 'react-native-elements';
import { GlobalStyle } from './styles';

function BottomBar({ state, descriptors, navigation }: { 
  state: { index: number, routes: { name: string, key: string }[], routeNames: string[] }, 
  descriptors: { options: { tabBarStyle: StyleProp<ViewStyle>}}[] }
  ) {
  const [index, setIndex] = useState(0)

  const [buttons, setButtons] = useState([
    { name: ScreenNames.DISCOVER.name, icon: GlobalStyle.ICON_DISCOVER_FA },
    { name: ScreenNames.MATCHES.name, icon: GlobalStyle.ICON_MATCHES_FA },
    { name: ScreenNames.MATES.name, icon: GlobalStyle.ICON_MATES_FA },
    { name: ScreenNames.POPULAR.name, icon: GlobalStyle.ICON_POPULAR_FA }
  ])

  const bottomBarStyleFromScreen = descriptors[state.routes[state.index].key].options.tabBarStyle || { }

  useEffect(() => {
    if (state.routeNames) {
      const currentScreenRouteName = state.routeNames[state.index]
      const currentScreenBottomBarIndex = buttons.findIndex(btn => btn.name == currentScreenRouteName)
      if (currentScreenBottomBarIndex > -1) {
        setIndex(currentScreenBottomBarIndex)
      }
    }
  }, [state, buttons, setIndex])

  const handlePress = (index: number) => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.linear);
    setIndex(index)
    navigation.navigate(buttons[index])
  }

  return (
    <View style={[styles.contianer, bottomBarStyleFromScreen]} >
      {buttons.map((button, i) => {
        return <BottomBarButton
          key={i}
          isSelected={i == index}
          icon={button.icon}
          name={button.name}
          handlePress={() => handlePress(i)}
        />
      })}
    </View>
  )
}

function BottomBarButton(props) {
  return (
    props.isSelected ?
      <View style={styles.selected}>
        <Icon type='font-awesome' color="black" name={props.icon} size={32} />
        <Text>{props.name}</Text>
      </View>
      :
      <TouchableOpacity onPress={() => props.handlePress()}>
        <Icon type='font-awesome' color="white" name={props.icon} size={32} />
      </TouchableOpacity>
  )
}

export const BOTTOM_BAR_HEIGHT = 48;
export const BOTTOM_BAR_POSITION = 22;

const styles = StyleSheet.create({
  contianer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 24,
    backgroundColor: '#000000dd',
    height: BOTTOM_BAR_HEIGHT,
    width: 360,
    alignSelf: 'center',
    bottom: BOTTOM_BAR_POSITION,
    position: 'absolute',
    flexDirection: `row`,
    gap: 36,
  },
  selected: {
    paddingHorizontal: 8,
    backgroundColor: '#FFFFFF',
    borderRadius: 24,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
    height: 36
  }
});

export default BottomBar;