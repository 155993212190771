import * as React from "react";
import { ImageBackground, StyleSheet } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { Movie } from "../../database";
import { getImageUrl } from "../../tmdb";
import { ReactNode } from "react";

export function MoviePoster(params: {movie: Movie, children: ReactNode}) {
    const { movie, children } = params;
    return (
        <ImageBackground
            style={[localStyles.container, localStyles.posterframe]}
            resizeMode="cover"
            source={{ uri: getImageUrl(movie.poster_path) }}
        >
            <LinearGradient
                style={localStyles.posterframe}
                locations={[0, 0.6, 1]}
                colors={["rgba(0, 0, 0, 0)", "rgba(0, 0, 0, 0.5)", "rgba(0, 0, 0, 1)"]} >
                {children}
            </LinearGradient>
        </ImageBackground>
    );
}

const localStyles = StyleSheet.create({
    container: {
        height: "100%",
    },
    posterframe: {
        height: 585,
        width: '100%'
    },
});