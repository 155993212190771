import * as React from "react";
import {
    View,
    Text,
    StyleSheet
} from "react-native";
import { Color } from "../../styles";
import { Header } from "./Header";
import { TmdbMovie } from "../../tmdb";

export function Plot(params: {movie: TmdbMovie}) {
    const { movie } = params;
    return (
        <View style={localStyles.container}>
            <Header name={"Plot summary"}/>
            <Text style={localStyles.overview}>
                {movie.overview}
            </Text>
        </View>
    );
}

const localStyles = StyleSheet.create({
    container: {
        width: '100%'
    },
    overview: {
        textAlign: "left",
        fontSize: 16,
        color: Color.textSecondaryColor,
    },
});


