import * as React from "react";
import {
    View,
    Text,
    Image,
    StyleSheet
} from "react-native";
import { Color } from "../../styles";
import { TmdbCast, getImageUrl } from "../../tmdb";

export function CastMember(params: { member: TmdbCast, showName: boolean}) {
    const { member, showName } = params;
    return (
        <View key={member.id} style={localStyles.container}>
            <Image
                style={localStyles.photo}
                source={{ uri: getImageUrl(member.profile_path) }} />
            { showName && 
                <View>
                    <Text style={[localStyles.actorName, localStyles.text]}>
                        {member.name}
                    </Text>
                    <Text style={localStyles.text}>
                        {member.character}
                    </Text>
                </View>
            }
        </View>
    );
}

const localStyles = StyleSheet.create({
    container: {
        flexDirection: "column",
        marginHorizontal: 4
    },
    photo: {
        width: 80,
        height: 120,
        borderRadius: 10,
    },
    text: {
        width: 80,
        textAlign: "center",
        fontSize: 12,
        color: Color.textPrimaryColor,
    },
    actorName: {
        fontWeight: "700",
    },
});