import { LocalStorage } from "./localStorage";

const API_LINK = 'https://api.themoviedb.org/3';
const IMAGE_LINK = 'https://image.tmdb.org/t/p/original'

const HEADERS = new Headers({
  'Authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIzMzBmZGZkOTdhYzVjNzJiMmQxMzQ1ZjQ5NWJlNmU1NCIsInN1YiI6IjY0MGZiYzg2MzIzZWJhMDA3Y2I3ZWE0ZSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.-Gnp55SVAcX24BLFViijMRUOWJPW2O3_h4Oix7FAMXc',
  'Accept': 'application/json'
});


// TODO this could return discriminating union for better type safety - https://www.typescriptlang.org/docs/handbook/unions-and-intersections.html#discriminating-unions
const mateFetch = async <T>(url: string): Promise<{ data?: T, err?: string }> => {
  try {
    const resp = await fetch(url, { headers: HEADERS });
    if (!resp.ok) {
      throw new Error(`Response not ok. Status: ${resp.status}`)
    }
    return { data: await resp.json() }
  } catch (e) {
    const err = `Request ${url} failed with error: ${e}`
    console.error(err)
    return { err }
  }
}

const movieUrl = (tmdb_id: string) => API_LINK + "/movie/" + tmdb_id

const movieCastUrl = (tmdb_id: string) => movieUrl(tmdb_id) + "/credits"

const movieImagesUrl = (tmdb_id: string) => movieUrl(tmdb_id) + "/images"

const movieVideosUrl = (tmdb_id: string) => movieUrl(tmdb_id) + "/videos"

const movieWatchProvidersUrl = (tmdb_id: string) => movieUrl(tmdb_id) + "/watch/providers"

const movieRecommendationsUrl = (tmdb_id: string) => movieUrl(tmdb_id) + "/recommendations"

async function cachedQuery<T>(key: string, query: () => Promise<{ data?: T, err?: string }>): Promise<T | undefined> {
  const cacheResult = await LocalStorage.get<T>(key)
  if (cacheResult) {
    return cacheResult;
  }
  const resp = await query();
  LocalStorage.store(key, resp.data)
  return resp.data
}

export const getImageUrl = (path: string): string => IMAGE_LINK + path;

export const TMDBApi = {
  getMovieDetails: async function(tmdb_id: string): Promise<TmdbMovie | undefined> {
    return cachedQuery<TmdbMovie>("tmdb-id-" + tmdb_id, () => mateFetch<TmdbMovie>(movieUrl(tmdb_id)))
  },
  getMovieImages: async function(tmdb_id: string): Promise<TmdbMovieImages | undefined> {
    return cachedQuery<TmdbMovieImages>("tmdb-images-id-" + tmdb_id, () => mateFetch<TmdbMovieImages>(movieImagesUrl(tmdb_id)))
  },
  getWatchProviders: async function(tmdb_id: string): Promise<TmdbWatchProviders | undefined> {
    return cachedQuery<TmdbWatchProviders>("tmdb-poviders-id-" + tmdb_id, () => mateFetch<TmdbWatchProviders>(movieWatchProvidersUrl(tmdb_id)))
  }
}

export const getCast = async (tmdb_id: string): Promise<TmdbCast[]> => {
  const resp = await mateFetch<{ cast: TmdbCast[] }>(movieCastUrl(tmdb_id))
  if (resp.err || !resp.data) {
    return []
  }
  return resp.data.cast
}

export const getMovieVideos = async (tmdb_id: string): Promise<TmdbVideo[]> => {
  const resp = await mateFetch<{ results: TmdbVideo[] }>(movieVideosUrl(tmdb_id))
  if (resp.err || !resp.data) {
    return []
  }
  return resp.data.results
}

export const getRecommendations = async (tmdb_id: string): Promise<TmdbMovie[]> => {
  const resp = await mateFetch<{ results: TmdbMovie[] }>(movieRecommendationsUrl(tmdb_id))
  if (resp.err || !resp.data) {
    return []
  }
  return resp.data.results
}

export const getWatchProviders = async (tmdb_id: string): Promise<TmdbWatchProviders> => {
  const resp = await mateFetch<TmdbWatchProviders>(movieWatchProvidersUrl(tmdb_id))
  return resp.data
}

export type TmdbMovie = {
  adult: boolean;
  backdrop_path: string;
  genres: { id: string, name: string }[];
  id: string;
  title: string;
  original_title: string;
  overview: string;
  popularity: number;
  poster_path: string;
  release_date: string;
  vote_average: number;
  vote_count: number;
  runtime: number
}

export type TmdbMovieImages = {
  id: string;
  posters: TmdbImage[],
  logos: TmdbImage[],
  backdrops: TmdbImage[]
}

export type TmdbImage = {
  "aspect_ratio": number,
  "height": number,
  "file_path": string,
  "vote_average": number,
  "vote_count": number,
  "width": number
}

export type TmdbCast = {
  "adult": boolean;
  "gender": number,
  "id": number,
  "name": string,
  "original_name": string,
  "popularity": number,
  "profile_path": string,
  "cast_id": number,
  "character": string,
  "credit_id": string,
  "order": number
}

export type TmdbVideo = {
  "iso_639_1": string,
  "iso_3166_1": string,
  "name": string,
  "key": string,
  "site": string,
  "size": number,
  "type": string,
  "official": boolean,
  "published_at": string,
  "id": string
}

export type TmdbWatchProviders = {
  id: number,
  results: { [key: string]: TmdbWatchProvider }
}

export type TmdbWatchProvider = {
  link: string,
  flatrate: TmdbWatchProviderEntry[],
  buy: TmdbWatchProviderEntry[],
  rent: TmdbWatchProviderEntry[],
}

export type TmdbWatchProviderEntry = {
  logo_path: string,
  provider_id: number,
  provider_name: string,
  display_priority: number
}