import { SafeAreaView, View, StyleSheet, Text, Image, Pressable } from "react-native";
import { MoviesBackground } from "../common/MoviesBackground";
import { getScreenWidth } from "../utils";
import { Color, GlobalImportStyles, GlobalStyle } from "../styles";
import { useCallback } from "react";
import { useNavigation } from "@react-navigation/native";
import ScreenNavigator, { ScreenNames } from "../navigation";

export function PublicInvitationScreem({ route }: { route: { params: { uid: string } } }) {
    const navigation = useNavigation()
    const uid = route.params.uid;

    const handleRegister = useCallback(async () => {
        ScreenNavigator.navigateToRegister(navigation, ScreenNames.INVITE.renderPath(uid))
    }, [])

    const handleLogin = useCallback(async () => {
        ScreenNavigator.navigateToLogin(navigation, ScreenNames.INVITE.renderPath(uid))
    }, [])

    return (
        <SafeAreaView style={styles.container}>
            <MoviesBackground />
            <View style={styles.formContainer}>
                <Text style={[styles.text, styles.header]}>Welcome to WatchMate!</Text> 
                <Text style={[styles.text, styles.info]}>******* invited you to join </Text>
                <Image style={styles.icon} source={require("../../assets/icon.png")} />
                <Text style={[styles.text, styles.info]}>Explore and discover exciting movie options. Swipe right on interesting stuff and watch it with friends and familly!</Text>
                <View style={styles.buttonContainer}>
                    <Pressable style={[styles.button, styles.button_register]} onPress={handleRegister}>
                        <Text style={[styles.button_text, styles.button_register_text]}>Register</Text>
                    </Pressable>
                    <Pressable style={[styles.button, styles.button_login]} onPress={handleLogin}>
                        <Text style={[styles.button_text, styles.button_login_text]}>Login</Text>
                    </Pressable>
                </View>
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        height: '100%',
        width: '100%',
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'black'
    },
    formContainer: {
        minWidth: Math.min(500, getScreenWidth() - 100),
        maxWidth: GlobalStyle.maxWidth,
        justifyContent: "center",
        alignItems: 'center',
        display: 'flex',
        flexDirection: "column",
        paddingHorizontal: 30,
        gap: 8,
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        borderRadius: 10,
        padding: 20,
        margin: 20
    },
    buttonContainer: {
        flexDirection: "row",
        gap: 16
    },
    button: {
        width: 120,
        height: 44,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 10
    },
    button_text: {
        fontSize: 18
    },
    button_register: {
        backgroundColor: Color.BUTTON_SECONDARY_BACKGROUND,
    },
    button_register_text: {
        color: Color.BUTTON_PRIMARY_TEXT,
    },
    button_login: {
        backgroundColor: Color.BUTTON_PRIMARY_BACKGROUND,
    },
    button_login_text: {
        color: Color.BUTTON_PRIMARY_TEXT,
    },
    icon: {
        width: 128,
        height: 128,
        borderRadius: 10
    },
    header: {
        fontSize: 20
    },   
    info: {
        fontSize: 15,
    },
    text: {
        color: Color.textPrimaryColor,
        textAlign: "center",
        maxWidth: 400
    }
});