import { SafeAreaView, ScrollView, StyleSheet, TouchableOpacity, View, Share } from 'react-native';
import { supabase } from "../supabase";
import { GroupWithMovies, MateWithMovies, database } from "../database"
import { useCallback, useMemo, useRef, useState } from 'react';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import MateListItem from './components/MateListItem';
import { APP_URL } from "@env"
import React from 'react';
import { Color, GlobalStyle } from "../styles";
import { Platform } from 'react-native';
import GroupListItem from './components/GroupListItem';
import CreateGroupBottomSheet from './CreateGroupBottomSheet';
import { ActionBar } from '../common/ActionBar';
import { Button, Icon } from 'react-native-elements';
import EmptyItem from '../common/EmptyItem';
import { WatchMateShare } from '../share';
import { BottomSheet } from '../common/bottomSheet';

function FriendsScreen() {
  const navigation = useNavigation()
  const sheetRef = useRef<BottomSheet>(null);

  const [mates, setMates] = useState(new Array<MateWithMovies>())
  const [groups, setGroups] = useState(new Array<GroupWithMovies>())

  const handleCreateGroup = useCallback(() => {
    console.log(`Navigating to 'CreateGroupScreen' with ${mates.length} friends fetched.`)
    sheetRef.current?.expand();
  }, [navigation, mates, sheetRef])

  useFocusEffect(
    useCallback(() => {
      database.fetchMates().then(setMates)
      database.fetchGroups().then(setGroups)
    }, [navigation])
  );

  const onGroupCreated = useCallback(() => {
    database.fetchGroups().then(newGroups => {
      console.log("groups reloaded", JSON.stringify(newGroups))
      setGroups(newGroups)
    })
  }, [groups, setGroups])

  return (
    <SafeAreaView style={styles.container} >
      <ActionBar style={styles.actionBar} backButton={false}>
        <View style={styles.actionBarButtonContainer}>
          <Button
            icon={{name: GlobalStyle.ICON_GROUP_FA, type: "font-awesome", color: "white"}}
            onPress={handleCreateGroup}
            type="clear"
          />
          <Button
            icon={{name: GlobalStyle.ICON_MATE_ADD_FA, type: "font-awesome", color: "white"}}
            onPress={WatchMateShare.handleInvite}
            type="clear"
          />
        </View>
      </ActionBar>
      <ScrollView 
        style={styles.mateList}
        contentContainerStyle={styles.mateListContainer}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        {[...groups, ...mates].sort((a, b) => (b.movies?.length || 0) - (a.movies?.length || 0)).map(item => {
          if (item.group_id) {
            return <GroupListItem group={item} key={item.group_id}/>
          } else {
            return <MateListItem mate={item} key={item.mate_id}/>
          }
        })}
      </ScrollView>
      {mates.length != 0 && <CreateGroupBottomSheet mates={mates} sheetRef={sheetRef} onGroupCreated={onGroupCreated}/> }
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Color.backgroundColor,
    width: '100%'
  },
  mateList: {
    width: '100%'
  },
  mateListContainer: {
    paddingBottom: GlobalStyle.listLastElementPaddingBottom
  },
  actionBar: {
    flexDirection: "row-reverse",
  },
  actionBarButtonContainer: {
    flexDirection: "row",
  },
});

export default FriendsScreen;