import { Pressable, SafeAreaView, StyleSheet, View, FlatList, Text, ViewStyle, Keyboard, Platform } from 'react-native';
import React, { Ref, useCallback, useMemo, useRef, useState } from 'react';
import { MateWithMovies, database } from '../database';
import { Button, Icon, Input } from 'react-native-elements';
import { Color, GlobalStyle } from '../styles';
import { BlurView } from 'expo-blur';
import { getScreenWidth } from '../utils';
import { BotomSheetStyles, BottomSheetItemText, BlurBackground, BottomSheetItem, BottomSheetHeaderButton, BottomSheetHeader, BottomSheetHeaderTitle, BottomSheetContainer, BottomSheet, BottomSheetInput, BottomSheetSectionHeader } from '../common/bottomSheet';
import { BottomSheetSectionList } from '@gorhom/bottom-sheet';

const SECTION_GROUP_MATES = "GROUP MATES".toLocaleUpperCase()
const SECTION_MATES = "MATES".toLocaleUpperCase();

const isGroupMatesSection = (section: {title: string}) => section.title == SECTION_GROUP_MATES
const isMatesSection = (section: {title: string}) => section.title == SECTION_MATES

function CreateGroupBottomSheet(
  { mates, sheetRef, onGroupCreated } 
  : { mates: MateWithMovies[], sheetRef: Ref<BottomSheet>, onGroupCreated: () => void }) {
  const [name, setName] = useState<string>()
  const initialSections = [
    {
      title: SECTION_GROUP_MATES,
      data: []
    }, {
      title: SECTION_MATES,
      data: mates
    }]
  const [groupMates, setGroupMates] = useState(initialSections)

  const snapPoints = useMemo(() => ["57%", "90%"], []);

  const handleClosePress = useCallback(() => {
    sheetRef.current?.close();
  }, [sheetRef]);

  const handleCreatePress = useCallback(async () => {
    const groupIds = groupMates.find(isGroupMatesSection)?.data.map(mate => mate.mate_id);
    if (name != null && groupIds && groupIds.length > 0) {
      await database.createGroup(name, groupIds);
      onGroupCreated();
      setName(undefined)
      setGroupMates(initialSections)
      Keyboard.dismiss();
      sheetRef.current?.close();
    } else {
      console.error("cannot create group add alert todo")
    }
  }, [name, groupMates, sheetRef, onGroupCreated])

  const addToGroupMate = useCallback((mate: MateWithMovies) => {
    setGroupMates(previous => [
      {
        title: SECTION_GROUP_MATES,
        data: [...previous.find(isGroupMatesSection)?.data || [], mate]
      }, {
        title: SECTION_MATES,
        data: previous.find(isMatesSection)?.data.filter(m => m.mate_id != mate.mate_id) || []
      }
    ])
  }, [setGroupMates])

  const removeFromGroupMate = useCallback((mate: MateWithMovies) => {
    setGroupMates(previous => [
      {
        title: SECTION_GROUP_MATES,
        data: previous.find(isGroupMatesSection)?.data.filter(m => m.mate_id != mate.mate_id) || []
      }, {
        title: SECTION_MATES,
        data: [...previous.find(isMatesSection)?.data || [], mate]
      }
    ])
  }, [setGroupMates])

  // render
  const renderSectionHeader = useCallback(
    ({ section }: { section: { title: string, data: MateWithMovies[] }}) => (
      <View>
        <BottomSheetSectionHeader value={section.title}/>
        {section.data.length == 0 && 
          <BottomSheetItem>
            <Button
              icon={{ 
                name: isGroupMatesSection(section) ? GlobalStyle.ICON_GROUP : GlobalStyle.ICON_EMPTY, 
                size: 22, 
                color: Color.LABELS_TERTIARY_DARK }}
              type="clear"
              title=""
            />
            <BottomSheetItemText 
              style={{color: Color.LABELS_TERTIARY_DARK}}
              value={isGroupMatesSection(section) ? "Choose mates from list below" : "All mates added!"}
            />
          </BottomSheetItem>
        }
      </View>
    ),
    [isGroupMatesSection]
  );
  const renderItem = useCallback(
    ({ item, index, section }: { item: MateWithMovies, index: number, section: { title: string, data: MateWithMovies[]}}) => (
      <BottomSheetItem index={index} size={section.data.length}>
        <Button
              icon={{ 
                name: isGroupMatesSection(section) ? GlobalStyle.ICON_REMOVE : GlobalStyle.ICON_ADD, 
                size: 22, 
                color: isGroupMatesSection(section) ? GlobalStyle.ICON_REMOVE_COLOR : GlobalStyle.ICON_ADD_COLOR }}
              iconContainerStyle={{backgroundColor: "white", borderRadius: 11 }}
              onPress={isGroupMatesSection(section) ? () => removeFromGroupMate(item) : () => addToGroupMate(item)}
              type="clear"
              title=""
          />
        <BottomSheetItemText index={index} size={section.data.length} value={item.mate_username}/>
      </BottomSheetItem>
    ),
    [removeFromGroupMate, addToGroupMate, isGroupMatesSection]
  );

  const renderHeader = useCallback(() => (
    <BottomSheetHeader>
      <BottomSheetHeaderButton title={"Cancel"} onPress={handleClosePress}/>
      <BottomSheetHeaderTitle value={"New group"}/>
      <BottomSheetHeaderButton title={"Create"} onPress={handleCreatePress}/>
    </BottomSheetHeader>
  ), [handleClosePress, handleCreatePress]);

  return (
      <BottomSheet sheetRef={sheetRef} snapPoints={snapPoints} header={renderHeader()}>
        <View>
          <BottomSheetInput               
              placeholder={"Group name"} 
              value={name} 
              setValue={setName}/>
          <BottomSheetSectionList
            sections={groupMates}
            keyExtractor={(i) => i.mate_id}
            renderSectionHeader={renderSectionHeader}
            renderItem={renderItem}
          />
        </View>
      </BottomSheet>
  )
}

const styles = StyleSheet.create({
  container: {

  },
});

export default CreateGroupBottomSheet;