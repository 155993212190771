import * as React from "react";
import {
    View,
    Text,
    Image,
    StyleSheet,
    FlatList,
    Pressable,
    Alert
} from "react-native";
import { Color } from "../../styles";
import { Header } from "./Header";
import { useCallback, useEffect, useState } from "react";
import { TmdbMovie, getImageUrl, getRecommendations } from "../../tmdb";
import { useNavigation } from "@react-navigation/native";
import ScreenNavigator from "../../navigation";
import { database } from "../../database";

export function RecomendedList(params: {movie: TmdbMovie}) {
    const navigation = useNavigation();
    const { movie } = params;
    const [recomendations, setRecomendations] = useState<TmdbMovie[]>([])

    useEffect(() => {
        getRecommendations(movie.id).then(data => setRecomendations(data
            .slice(0, Math.min(6, data.length))
            .filter(movie => movie.poster_path != null)))
    }, [movie])

    const renderItem = useCallback(({item: movie}) => (
            <Pressable onPress={() => database
                .fetchWatchableByTmdbId(movie.id)
                .then(dbMovie => ScreenNavigator.navigateToMovieDetails(navigation, dbMovie.id))
                .catch(() => {
                    console.log("movie not found in db")
                    Alert.alert('Movie not available yet!', 'Please try another one. We will take care about that.', [
                        {text: 'OK', onPress: () => console.log('OK Pressed')},
                      ]);
                })
            }>
                <Recomended movie={movie}/>
            </Pressable>
    ), [])

    if (recomendations.length == 0) {
        return <></>
    }

    return (
        <View style={localStyles.container}>
            <Header name={"Recommended"}/>
            <FlatList
                data={recomendations}
                renderItem={renderItem}
                keyExtractor={item => item.id}
                initialNumToRender={1}
                maxToRenderPerBatch={1}
                updateCellsBatchingPeriod={500}
                contentContainerStyle={localStyles.innerContainer}
                horizontal={true}
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
            />
        </View>
    );
}

const localStyles = StyleSheet.create({
    container: {
        width: '100%',
    },
    innerContainer: {
        gap: 8
    },
    poster: {
        width: 150,
        height: 225,
    },
    movieTitle: {
        maxWidth: 150,
        textAlign: "center",
        fontSize: 18,
        color: Color.textPrimaryColor,
    },
});

function Recomended(params : { movie: TmdbMovie }) {
    const { movie } = params;
    return (
        <View>
            <Image
                style={localStyles.poster}
                source={{ uri: getImageUrl(movie.poster_path) }} />
            <Text style={localStyles.movieTitle}>{movie.title}</Text>
        </View>
    );
}
