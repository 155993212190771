import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { Icon } from "react-native-elements";

type Type = "first" | "middle" | "last" | "single"

export default function Counter(props: { count: number, icon: string, type?: Type }) {
    const type = props.type || "single"
    return (
        <View style={[styles.container, getStyle(type)]}>
            <Text style={styles.counterText}>{props.count}</Text>
            <Icon style={styles.counterIcon} name={props.icon} type="font-awesome" color="white" size={32} />
        </View>
    )
}

function getStyle(type: Type) {
    switch(type) {
        case "first": return styles.first;
        case "middle": return;
        case "last": return styles.last;
        case "single": return styles.single;
        default: return styles.single;
    }
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#000000aa',
        padding: 8,
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
        alignItems: 'center'
    },
    counterText: {
        color: 'white',
        fontSize: 24,
    },
    counterIcon: {
        color: 'white'
    },
    first: {
        borderTopStartRadius: 6,
        borderBottomStartRadius: 6
    },
    single: {
        borderRadius: 6,
    },
    last: {
        borderTopEndRadius: 6,
        borderBottomEndRadius: 6
    }
});