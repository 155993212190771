import React, { useEffect, useState } from 'react'
import { StyleSheet, View, Text, useWindowDimensions } from 'react-native'
import { supabase } from '../supabase'
import { Button, Divider, Input } from 'react-native-elements'
import { getScreenWidth } from '../utils'
import { MoviesBackground } from '../common/MoviesBackground'
import { Color } from '../styles'

export default function Auth(params: { route: { params: { signIn?: boolean }}}) {
  const { width, height } = useWindowDimensions();
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [repeatedPassword, setRepeatedPasword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [signIn, setSignIn] = useState<boolean>(true)

  useEffect(() => {
    if (params && params.route && params.route.params && typeof params.route.params.signIn !== 'undefined') {
      setSignIn(params.route.params.signIn)
    }
  }, [params, setSignIn])

  async function switchSignIn() {
    setSignIn(oldValue => !oldValue)
  }

  async function signInWithEmail() {
    setLoading(true)
    const { error: currentError } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    })

    if (currentError) {
      setError(currentError.message)
    }
    setLoading(false)
  }

  async function signUpWithEmail() {
    if (password != repeatedPassword) {
      setError("Passwords do not match!")
      return;
    }
    setLoading(true)
    const {
      data: { session },
      error: currentError,
    } = await supabase.auth.signUp({
      email: email,
      password: password,
    })

    if (currentError) {
      setError(currentError.message)
    } else if (!session) {
      setError("")
      setSuccess('Please check your inbox for email verification!')
    }
    setLoading(false)
  }

  return (
    <View style={[styles.container, { width: width, height: height}]}>
      <MoviesBackground/>
      <View style={styles.formContainer}>
        <Text style={styles.title}>WatchMate</Text>
        <View style={styles.inputContainer}>
          <Input
            inputStyle={styles.input}
            label="Email"
            leftIcon={{ type: 'font-awesome', name: 'envelope', color: 'white' }}
            onChangeText={(text) => setEmail(text)}
            value={email}
            placeholder="email@address.com"
            autoCapitalize={'none'}
          />
        </View>
        <View style={styles.inputContainer}>
          <Input
            inputStyle={styles.input}
            label="Password"
            leftIcon={{ type: 'font-awesome', name: 'lock', color: 'white' }}
            onChangeText={(text) => setPassword(text)}
            value={password}
            secureTextEntry={true}
            placeholder="Password"
            autoCapitalize={'none'}
          />
        </View>
        { !signIn ?
          <View style={styles.inputContainer}>
            <Input
              inputStyle={styles.input}
              label="Repeat password"
              leftIcon={{ type: 'font-awesome', name: 'lock', color: 'white' }}
              onChangeText={(text) => setRepeatedPasword(text)}
              value={repeatedPassword}
              secureTextEntry={true}
              placeholder="Repeat password"
              autoCapitalize={'none'}
            />
          </View> : <></> }
        {signIn ? 
        <View style={styles.inputContainer}>
          <Button style={styles.inputContainer} title="Sign in" disabled={loading} onPress={() => signInWithEmail()} />
        </View>
        :
        <View style={styles.inputContainer}>
          <Button style={styles.button} title="Sign up" disabled={loading} onPress={() => signUpWithEmail()} />
        </View>
        }
        {error != "" ?
          <Text style={styles.error}>{error}</Text> :
          <></>}
        {success != "" ?
          <Text style={styles.success}>{success}</Text> :
          <></>}
        <Divider width={5} color='#000000'/>
        { signIn ?
          <Button title={"Don't have an account? Register!"} type="clear" onPress={() => switchSignIn()}/> :
          <Button title={"Have an account already? Sign in!"} type="clear" onPress={() => switchSignIn()}/> }
        <Button title={"Forgot your password?"} type="clear"/>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    justifyContent: "center",
    alignItems:"center",
    backgroundColor: 'black'
  },
  title: {
    color: "#FFFFFF",
    fontSize: 48,
    fontWeight: "800",
  },
  error: {
    color: Color.textErrorColor
  },
  success: {
    color: "#2ecc70"
  },
  formContainer: {
    minWidth: Math.min(500, getScreenWidth() - 100),
    justifyContent: "center",
    alignItems: 'center',
    display: 'flex',
    flexDirection: "column",
    paddingHorizontal: 30,
    gap: 8,
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    borderRadius: 10,
    padding: 20,
    margin: 20
  }, 
  inputContainer: {
    width: '100%',
    minWidth: 200,
  },
  input: {
    color: '#FFFFFF',
  },
  button: {
    width: '100%'
  }
})