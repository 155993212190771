import * as React from "react";
import {
    View,
    Text,
    Image,
    StyleSheet,
    Pressable
} from "react-native";
import { Color } from "../../styles";
import { Mate, Movie } from "../../database";
import { useEffect, useState } from "react";
import { database } from "../../database"

export function FriendsMatch({ movie, onPress }: { movie: Movie, onPress: (mates: Mate[]) => void }) {
    const [mates, setMates] = useState<Mate[]>([])

    useEffect(() => {
        database.fetchWatchableMatchMates(movie.id, true).then(setMates)
    }, [movie])

    return (
        <Pressable style={localStyles.container} onPress={() => onPress(mates)}>
            <View style={localStyles.avatars}>
                <Image
                    style={localStyles.avatar}
                    source={{ uri: "https://i.pravatar.cc/150?img=5g" }} />
                <Image
                    style={[localStyles.avatar, localStyles.avatarNext]}
                    source={{ uri: "https://i.pravatar.cc/150?img=6" }} />
                <Image
                    style={[localStyles.avatar, localStyles.avatarNext]}
                    source={{ uri: "https://i.pravatar.cc/150?img=7" }} />
                <Image
                    style={[localStyles.avatar, localStyles.avatarNext]}
                    source={{ uri: "https://i.pravatar.cc/150?img=25" }} />
            </View>
            <Text style={localStyles.text}>
                {mates.length} friend’s want to see this
            </Text>
        </Pressable>
    );
}

const localStyles = StyleSheet.create({
    container: {
        flexDirection: "row",
        alignItems: "center",
    },
    avatars: {
        flexDirection: "row",
        alignItems: "center",
        marginHorizontal: 8
    },
    avatar: {
        height: 36,
        width: 36,
        borderRadius: 18
    },
    avatarNext: {
        marginLeft: -18,
    },
    text: {
        textAlign: "center",
        color: Color.textPrimaryColor,
        fontSize: 16,
    }
});