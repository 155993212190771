import { NavigationProp } from "@react-navigation/native";
import { Movie } from "./database";

type N = NavigationProp<ReactNavigation.RootParamList>

export const ScreenNames = {
   DISCOVER: {
      name: "Discover",
      path: ""
   },
   MATCHES: {
      name: "Matches",
      path: "matches"
   },
   MATES: {
      name: "Mates",
      path: "mates"
   },
   POPULAR: {
      name: "Popular",
      path: "popular"
   },
   MOVIES_DETAILS: {
      name: "MovieDetails",
      path: "movies/:id",
      renderPath: (id: string) => `movies/${id}`
   },
   MATE_MOVIES: {
      name: "MateMovies",
      path: "mate/:id"
   },
   GROUP_MOVIES: {
      name: "GroupMovies",
      path: "group/:id"
   },
   SETTINGS: {
      name: "Settings",
      path: "settings"
   },
   INVITE: {
      name: "InvitationSummary",
      path: "invites/:uid",
      renderPath: (uid: string) => `invites/${uid}`,
      match: (path: string) => /^invites\/([a-zA-Z0-9\-]{36}$)/.test(path),
      getUUID: (path: string) => /^invites\/([a-zA-Z0-9\-]{36}$)/.exec(path)?.at(1),
   },
   AUTH: {
      name: "Authentication",
      path: ""
   }
}

const ScreenNavigator = {
   navigateToDiscovery: (navigation: N) => {
      navigation.navigate(ScreenNames.DISCOVER.name)
   },
   navigateToSettingsScreen: (navigation: N) => {
      navigation.navigate(ScreenNames.SETTINGS.name)
   },
   navigateToMovieDetails: (navigation: N, id: string) => {
      navigation.navigate(ScreenNames.MOVIES_DETAILS.name, { id: id })
   },
   navigateToMateMovies: (navigation: N, id: string) => {
      navigation.navigate(ScreenNames.MATE_MOVIES.name, { id: id })
   },
   navigateToGroupMovies: (navigation: N, id: string) => {
      navigation.navigate(ScreenNames.GROUP_MOVIES.name, { id: id })
   },
   navigateToLogin: (navigation: N, redirectPath?: string) => {
      navigation.navigate(ScreenNames.AUTH.name, { signIn: true, redirectPath: redirectPath })
   },
   navigateToRegister: (navigation: N, redirectPath?: string) => {
      navigation.navigate(ScreenNames.AUTH.name, { signIn: false, redirectPath: redirectPath })
   },
   redirectTo: (navigation: N, redirectPath: string) => {
      if (redirectPath && ScreenNames.INVITE.match(redirectPath)) {
         const uid = ScreenNames.INVITE.getUUID(redirectPath)
         console.debug("navigating to invites uid", uid)
         navigation.navigate(ScreenNames.INVITE.name, { uid: uid})
      } else {
         console.debug("navigating to default Discover screen")
         navigation.navigate(ScreenNames.DISCOVER.name)
      }
   }
}

export default ScreenNavigator;