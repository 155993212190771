import { Dimensions } from 'react-native'

// fix for 100% height in 'asbolute' for react-native
export const getScreenHeight = () => Dimensions.get("window").height

export const getScreenWidth = () => Dimensions.get("window").width

export const truncateTo = (text: string, limit: number): string => {
    if (text === undefined) {
        return ""
    }
    if (text.length <= limit) {
        return text;
    }
    return text.slice(0, limit - 3) + "..."
}

export function toHoursAndMinutes(totalMinutes: number) {
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);

    return `${hours}h ${padTo2Digits(minutes)}m`;
}

function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
}

export function arrayIntersection<T extends {id: string}>(arrays: T[][]): T[] {
    const map = new Map<string, { data: T, counter: number }>()
    for (let indexX = 0; indexX < arrays.length; indexX++) {
        const array = arrays[indexX];
        for (let indexY = 0; indexY < array.length; indexY++) {
            const element = array[indexY];
            const x = map.get(element.id)
            if (x) {
                map.set(element.id, { data: x.data, counter: x.counter + 1})
            } else {
                map.set(element.id, { data: element, counter: 1})
            }
        }
        
    }
    return Array.from(map.values()).filter(x => x.counter == arrays.length).map(x => x.data);
  }