import AsyncStorage from "@react-native-async-storage/async-storage"

export const LocalStorage = {

    store: async function<T>(key: string, value: T) {
        try {
            await AsyncStorage.setItem(key, JSON.stringify(value))
            console.log("saved to local storage by id", key)
        } catch(e) {
            console.error("failed to save to local storage", e)
        }
    },

    get: async function<T>(key: string): Promise<T | null> {
        try {
            const jsonValue = await AsyncStorage.getItem(key)
            console.log(`load from local storage by id=${key} ${jsonValue != null ? "successfully" : "not found"}`)
            return jsonValue != null ? JSON.parse(jsonValue) as T : null;
        } catch(e) {
            console.error("failed to read from local storage", e)
        }
        return null;
    },

}