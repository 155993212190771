import React from "react";
import { StyleSheet, ImageBackground, View } from "react-native";
import { getImageUrl } from "../../tmdb";
import { Movie } from "../../database";
import { LinearGradient } from "expo-linear-gradient";
import { GlobalStyle } from "../../styles";

export default function EmptyListElement() {
    return (
        <View style={styles.container}>
            
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        height: GlobalStyle.posterHeightCard,
    },
});