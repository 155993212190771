import { BlurView } from 'expo-blur';
import { Platform, StyleSheet, View, Text, StyleProp, ViewStyle, TextStyle } from 'react-native';
import { Color, GlobalStyle } from '../styles';
import { getScreenWidth } from '../utils';
import { ReactNode, Ref, useCallback } from 'react';
import { Button, Input } from 'react-native-elements';
import {BottomSheetBackdrop, BottomSheetBackdropProps, default as ExternalBottomSheet} from "@gorhom/bottom-sheet";


export function BlurBackground({style}) {
    return (
        <BlurView
            style={{
                ...style,
                overflow: 'hidden',
                borderTopEndRadius: 10,
                borderTopStartRadius: 10,
                backgroundColor: Color.BACKGROUND_SECONDARY,
            }}
            intensity={Platform.OS == "web" ? 50 : 15}
        />
    )
  }
  
function getItemStyle(index: number, size: number) {
    if (size == 1) {
        return BotomSheetStyles.itemContainerSingle;
    } else if (index == 0) {
        return BotomSheetStyles.itemContainerFirst;
    } else if (index == size - 1) {
        return BotomSheetStyles.itemContainerLast;
    } else {
        return BotomSheetStyles.itemContainerMiddle;
    }
}
  
function getItemSeparatorStyle(index: number, size: number) {
    if (size != 0 && index < size -1) {
        return BotomSheetStyles.itemContainerTextWithSeparator;
    }
}

export const BottomSheetStyleValues = {
    HORIZONTAL_MARGIN: 16,
    VERTICAL_MARGIN: 12,
    BORDER_RADIUS: 10,
    FONT_SIZE_HEADER: 20,
    FONT_SIZE_PRIMARY: 16,
    FONT_SIZE_SECONDARY: 13,
}

export function BottomSheetHeader({ children, style } : { children: ReactNode, style?: StyleProp<TextStyle>}) {
  return (
    <View style={[BotomSheetStyles.headerContainer, style]}>
      {children}
    </View>
  )
}

export function BottomSheetHeaderButton({ title, onPress } : { title: string, onPress: () => void}) {
  return (
    <Button type={"clear"} title={title} onPress={onPress}/>
  )
}

export function BottomSheetHeaderTitle({ value } : { value: string}) {
  return (
    <Text style={BotomSheetStyles.headerTitle}>{value}</Text>
  )
}

export function BottomSheetContainer({ children } : { children: ReactNode}) {
  return (
    <View style={BotomSheetStyles.contentContainer}>
      {children}
    </View>
  )
}

export function BottomSheetItem({ index = 0, size = 1, children } : { index?: number, size?: number, children: ReactNode}) {
  return (
    <View style={[BotomSheetStyles.itemContainer, getItemStyle(index, size)]}>
      {children}
    </View>
  )
}

export function BottomSheetItemText({ index = 0, size = 1, value, style } 
  : { index?: number, size?: number, value: string, style?: StyleProp<TextStyle>}) {
  return (
    <View style={[BotomSheetStyles.itemContainerText, getItemSeparatorStyle(index, size)]}>
      <Text style={[BotomSheetStyles.text, style]}>{value}</Text>
    </View>
  )
}

export function BottomSheetSectionHeader({ value } : { value: string}) {
  return (
    <View style={BotomSheetStyles.sectionHeaderContainer}>
      <Text style={BotomSheetStyles.sectionTitle}>{value}</Text>
    </View>
  )
}

export function BottomSheetInput({ value, setValue, placeholder } 
  : { value: string | undefined, setValue: (text: string) => void, placeholder: string}) {
  return (
    <View style={[BotomSheetStyles.itemContainer, BotomSheetStyles.itemContainerSingle, BotomSheetStyles.sectionContainer]}>
      <Input 
        style={BotomSheetStyles.text} 
        inputMode='text' 
        maxLength={50} 
        renderErrorMessage={false} 
        inputContainerStyle={{borderBottomWidth: 0}} 
        placeholderTextColor={Color.LABELS_TERTIARY_DARK}
        placeholder={placeholder} 
        value={value} 
        onChangeText={setValue}/>
    </View>
  )
}

export function BottomSheet({ children, header, sheetRef, snapPoints } 
  : { children: ReactNode, header: ReactNode, sheetRef: Ref<BottomSheet>, snapPoints: Array<string | number>}) {
  const renderBackdrop = useCallback((props: BottomSheetBackdropProps) => (
      <BottomSheetBackdrop
        {...props}
        appearsOnIndex={0}
        disappearsOnIndex={-1}
        pressBehavior="close"
      />
    ), []);
  return (
    <ExternalBottomSheet
        ref={sheetRef}
        index={-1}
        enablePanDownToClose={true}
        snapPoints={snapPoints}
        backgroundComponent={BlurBackground}
        backdropComponent={Platform.OS != 'web' ? renderBackdrop : undefined}
        style={BotomSheetStyles.bottomSheet}
        containerStyle={BotomSheetStyles.bottomSheetContainer}
      >
      {header}
      <BottomSheetContainer>
        {children}
      </BottomSheetContainer>
    </ExternalBottomSheet>
  )
}

export type BottomSheet = ExternalBottomSheet;

export const BotomSheetStyles = StyleSheet.create({
    sectionTitle: {
      color: Color.LABELS_SECONDARY_DARK,
      fontSize: BottomSheetStyleValues.FONT_SIZE_SECONDARY
    },
    text: {
      color: Color.LABELS_PRIMARY_DARK,
      fontSize: BottomSheetStyleValues.FONT_SIZE_PRIMARY,
    },
    headerContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginHorizontal: BottomSheetStyleValues.HORIZONTAL_MARGIN
    },
    headerTitle: {
      fontSize: BottomSheetStyleValues.FONT_SIZE_HEADER,
      fontWeight: "600",
      color: Color.LABELS_PRIMARY_DARK
    },
    contentContainer: {
      marginHorizontal: BottomSheetStyleValues.HORIZONTAL_MARGIN
    },
    sectionContainer: {
      marginVertical: BottomSheetStyleValues.VERTICAL_MARGIN
    },
    sectionHeaderContainer: {
      marginHorizontal: BottomSheetStyleValues.HORIZONTAL_MARGIN,
      marginTop: BottomSheetStyleValues.VERTICAL_MARGIN
    },
    itemContainer: {
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: Color.BACKGROUND_PRIMARY_ELEVATED,
      paddingVertical: 2,
      gap: 10
    },
    itemContainerFirst: {
      borderTopEndRadius: BottomSheetStyleValues.BORDER_RADIUS,
      borderTopStartRadius: BottomSheetStyleValues.BORDER_RADIUS,
      marginTop: BottomSheetStyleValues.VERTICAL_MARGIN,
    },
    itemContainerMiddle: {
      borderRadius: 0,
    },
    itemContainerLast: {
      borderBottomEndRadius: BottomSheetStyleValues.BORDER_RADIUS,
      borderBottomStartRadius: BottomSheetStyleValues.BORDER_RADIUS,
      marginBottom: BottomSheetStyleValues.VERTICAL_MARGIN
    },
    itemContainerSingle: {
      borderRadius: BottomSheetStyleValues.BORDER_RADIUS,
      marginVertical: BottomSheetStyleValues.VERTICAL_MARGIN
    },
    itemContainerText: {
      flex: 1,
      paddingVertical: 11,
    },
    itemContainerTextWithSeparator: {
      borderBottomColor: Color.SEPARATORS_DARK,
      borderBottomWidth: 1,
    },
    bottomSheet: {
      
    },
    bottomSheetContainer: {
      marginHorizontal: getScreenWidth() > GlobalStyle.maxWidth ? (getScreenWidth() - GlobalStyle.maxWidth) / 2 : 0,
      maxWidth: GlobalStyle.maxWidth,
    }
  });