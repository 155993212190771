import { StyleSheet } from 'react-native';

export const Color = {
    textPrimaryColor: "#FFFFFF",
    textSecondaryColor: "#6f6e78",
    textErrorColor: "#FF3B30",
    backgroundColor: "#000000",

    LABELS_PRIMARY_DARK: "rgba(255, 255, 255, 1)",
    LABELS_SECONDARY_DARK: "rgba(235, 235, 245, 0.6)",
    LABELS_TERTIARY_DARK: "rgba(235, 235, 245, 0.3)",
    SEPARATORS_DARK: "rgba(84, 84, 88, 0.65)",
    BACKGROUND_PRIMARY_ELEVATED: "rgba(28, 28, 30, 1)",
    BACKGROUND_SECONDARY: "rgba(37, 37, 37, 0.9)",

    BUTTON_PRIMARY_BACKGROUND: "rgba(32, 137, 220, 1)",
    BUTTON_PRIMARY_TEXT: "#FFFFFF",
    BUTTON_SECONDARY_BACKGROUND: "rgba(37, 37, 37, 0.9)",
    BUTTON_CANCEL_TEXT: "#FF3B30"

};

export const GlobalStyle = {

    posterHeightCard: 225,
    posterWidthCard: 150,
    listLastElementPaddingBottom: 100,
    SafeAreaPaddingTop: 40,

    maxWidth: 720,

    ICON_DISCOVER_FA: "compass",
    ICON_MATCHES_FA: "film",
    ICON_MATES_FA: "group",
    ICON_POPULAR_FA: "bar-chart-o",

    ICON_GROUP_FA: "group",
    ICON_MATE_ADD_FA: "user-plus",
    ICON_MATE_DELETE_FA: "user-times",
    ICON_CHECK_FA: "check-circle",
    ICON_UNCHECKED_FA: "check-circle-o",

    ICON_SHARE: "ios-share",

    ICON_ADD: "add-circle",
    ICON_ADD_COLOR: "green",
    ICON_REMOVE: "remove-circle",
    ICON_REMOVE_COLOR: "red",
    ICON_GROUP: "person-add",
    ICON_EMPTY: "sentiment-very-satisfied",
}

export const GlobalImportStyles = StyleSheet.create({
    transparentButtonBackground: {
        backgroundColor: '#000000aa',
        // padding: 8,
    }
});