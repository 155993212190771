import * as React from "react";
import { Text, StyleSheet } from "react-native";
import { Color } from "../../styles";

export function Header({ name }) {
    return (
        <Text style={styles.header}>
            {name}
        </Text>
    );
}

export const styles = StyleSheet.create({
    header: {
        fontWeight: "700",
        textAlign: "left",
        fontSize: 18,
        color: Color.textPrimaryColor,
        marginBottom: 8
    },
});