import 'react-native-url-polyfill/auto'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { createClient } from '@supabase/supabase-js'

const supabaseUrl = "https://roccwtrswleyfeumzeyz.supabase.co"
const supabaseAnonKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJvY2N3dHJzd2xleWZldW16ZXl6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTg3MDE1ODUsImV4cCI6MjAxNDI3NzU4NX0.qCwX-dUmVmA9zvu4pZ2PYC9-dcn1oRLABCVgJKk7ZMs"

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    storage: AsyncStorage,
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: false,
  },
})