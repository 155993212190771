import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { useState, useCallback, ReactNode, useEffect } from 'react';
import { MovieCountMatch, database } from '../database';
import { GlobalStyle } from '../styles';
import { SwipeableList, SwipeableListButton, SwipeType } from './SwipeableList';
import MovieCard from '../movies-screen/MovieCard';

function MoviesPage({ movies, showMatch, countIcon, containerStyle}: { movies: MovieCountMatch[], showMatch?: boolean, countIcon?: string, containerStyle?: StyleProp<ViewStyle>}) {
  const [moviesData, setMovies] = useState<MovieCountMatch[]>([])

  useEffect(() => {
    setMovies(movies)
  }, [movies])

  const match = useCallback((movie: MovieCountMatch) => {
    setMovies(previous => previous.filter(m => m.movie.id != movie.movie.id))
    database.createMatch(movie.movie.id, true)
  }, [setMovies])

  const unmatch = useCallback((movie: MovieCountMatch) => {
    setMovies(previous => previous.filter(m => m.movie.id != movie.movie.id))
    database.deleteMatch(movie.movie.id)
  }, [setMovies])

  const matchButton = useCallback((index: number, item: MovieCountMatch) => {
    return (
      <SwipeableListButton
        backgroundColor={"blue"}
        icon={{ name: "add-circle", color: "white" }}
        type='clear'
        title={"Match"}
        titleStyle={{ color: "white" }}
        onPress={() => match(item)}
      />
    );
  }, [match]);

  const unmatchButton = useCallback((index: number, item: MovieCountMatch) => {
    return (
      <SwipeableListButton
        backgroundColor={"red"}
        icon={{ name: "delete", color: "white" }}
        type='clear'
        title={"Unmatch"}
        titleStyle={{ color: "white" }}
        onPress={() => unmatch(item)}
      />
    );
  }, [unmatch]);

  const renderMovieCard = useCallback((data: { index: number, item: MovieCountMatch }) => (
    <MovieCard movie={data.item.movie} counter={data.item.count} showMatch={showMatch} match={data.item.match}/>
  ), [])

  return (
    <SwipeableList<MovieCountMatch & { swipeType: SwipeType}>
      style={styles.container}
      contentContainerStyle={[styles.contentContainer, containerStyle]}
      data={moviesData.map(movie => ({ ...movie, swipeType: movie.match ? "right" : "left" }))}
      initialNumToRender={5}
      maxToRenderPerBatch={5}
      updateCellsBatchingPeriod={500}
      keyExtractor={item => item.movie.id}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      renderItem={renderMovieCard}
      rightButton={unmatchButton}
      leftButton={matchButton}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
  },
  contentContainer: {
    paddingBottom: GlobalStyle.listLastElementPaddingBottom
  }
});

export default MoviesPage;