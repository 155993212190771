import { ReactNode, useCallback } from 'react';
import { ColorValue, FlatListProps, View, Text, ListRenderItem, ListRenderItemInfo } from 'react-native';
import { SwipeListView, SwipeRow } from 'react-native-swipe-list-view';
import { Button, ButtonProps } from 'react-native-elements';

const Style = {
    ACTION_SIZE: 150,
    BUTTON_SIZE: 100,
    BUTTON_MARGIN: 25,
    PREVIEW_DURATION: 1000
}

export type SwipeType = "right" | "left" | "both" | "none"

export function SwipeableList<T extends { swipeType: SwipeType}>(params: {
    data: ArrayLike<T>,
    renderItem: ListRenderItem<T>,
    keyExtractor: (item: T, index: number) => string,
    rightButton?: (index: number, item: T) => ReactNode,
    leftButton?: (index: number, item: T) => ReactNode
} & FlatListProps<T>
) {
    const hiddenItem = useCallback((data: { index: number, item: T }) => {
        return (
            <View style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                {(data.item.swipeType == "left" || data.item.swipeType == "both") && params.leftButton ? params.leftButton(data.index, data.item) : <View></View>}
                {(data.item.swipeType == "right" || data.item.swipeType == "both") && params.rightButton ? params.rightButton(data.index, data.item) : <></>}
            </View>
        );
    }, [params.rightButton, params.leftButton]);

    const renderItem = useCallback((data: ListRenderItemInfo<T>) => {
        return (
            <SwipeRow<T>
                // right button
                rightOpenValue={-Style.ACTION_SIZE}
                disableLeftSwipe={data.item.swipeType == "left" || data.item.swipeType == "both"}

                // left button
                leftOpenValue={Style.ACTION_SIZE}
                disableRightSwipe={data.item.swipeType == "right" || data.item.swipeType == "both"}
            >
                {hiddenItem(data)}
                {params.renderItem(data)}
            </SwipeRow>
        )
    }, [])

    return (
        <SwipeListView
            // FlatList props
            {...params}
            data={params.data}
            renderItem={renderItem}
            keyExtractor={params.keyExtractor}

            // common props
            closeOnRowBeginSwipe={true}
            closeOnScroll={false}
            swipeToOpenPercent={20}
            swipeToClosePercent={20}

            previewRowKey={params.data.length > 0 ? params.keyExtractor(params.data[Math.min(1, params.data.length - 1)], Math.min(1, params.data.length - 1)) : ""}
            previewOpenValue={params.rightButton ? -Style.ACTION_SIZE : params.leftButton ? Style.ACTION_SIZE : 0}
            previewDuration={Style.PREVIEW_DURATION}
        />
    );
}

export function SwipeableListButton(params: { backgroundColor: ColorValue } & ButtonProps) {
    return (
        <View style={{
            backgroundColor: params.backgroundColor,
            height: "100%",
            width: Style.ACTION_SIZE,
            justifyContent: "center",
            alignItems: "center"
        }}>
            <Button
                {...params}
                style={{ width: Style.BUTTON_SIZE, marginHorizontal: Style.BUTTON_MARGIN }}
            />
        </View>
    )
}