import { useNavigation } from "@react-navigation/native";
import { ReactNode, useCallback } from "react";
import { StyleProp, View, Text, StyleSheet, ViewStyle, SafeAreaView, } from "react-native";
import { Button, Icon } from "react-native-elements";
import { BackButton } from "./BackButton";
import { Color } from "../styles";

export function ActionBar({ title, style, children, backButton = true }: { backButton?: boolean, title?: string, style?: StyleProp<ViewStyle>, children?: ReactNode }) {
    return (
        <SafeAreaView style={[styles.actionBar, style]}>
            {backButton && <BackButton />}
            {title && <Text style={styles.title}>{title}</Text>}
            {children}
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    actionBar: {
        width: "100%",
        flexDirection: "row",
        alignItems: "center"
    },
    title: {
        fontSize: 17,
        color: Color.textPrimaryColor
    }
});