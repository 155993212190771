import { StyleSheet, Text, View, ImageBackground, Pressable } from 'react-native';
import { getImageUrl } from "../tmdb"
import { LinearGradient } from "expo-linear-gradient"
import { getScreenHeight } from '../utils';
import { Button, Icon } from 'react-native-elements';
import { useNavigation } from '@react-navigation/native';
import { useCallback, useEffect } from 'react';
import { Movie } from '../database';
import ScreenNavigator from '../navigation';
import Counter from '../common/Counter';
import { Color, GlobalStyle } from '../styles';

function MovieCard({ movie, counter, match, showMatch }: { movie: Movie, counter: number, match?: boolean, showMatch?: boolean }) {
  const navigation = useNavigation()

  const onCardPressed = () => {
    ScreenNavigator.navigateToMovieDetails(navigation, movie.id)
  }

  const matchedMovie = useCallback(() => {
    return (
      <View style={styles.match}>
        <Icon name={GlobalStyle.ICON_CHECK_FA} type="font-awesome" color="green"/>
        <Text style={styles.matchText}>Matched!</Text>
      </View>
    )
  }, [])

  const newMovie = useCallback(() => {
    return (
      <View style={styles.match}>
        <Icon name={GlobalStyle.ICON_UNCHECKED_FA} type="font-awesome" color="red"/>
        <Text style={styles.matchText}>New Movie!</Text>
      </View>
    )
  }, [])

  return (
    <View style={styles.card}>
      <Pressable style={styles.poster} onPress={onCardPressed}>
        <ImageBackground style={styles.poster} source={{ uri: getImageUrl(movie.backdrop_path) }}>
          <LinearGradient 
            colors={['transparent', '#00000000', '#000000']} 
            style={styles.poster}>
            <View style={styles.counter}>
              <Counter icon={GlobalStyle.ICON_GROUP_FA} count={counter} />
            </View>
            { showMatch && ( match == true ? matchedMovie() : newMovie() ) }
            <Text style={styles.title} numberOfLines={2}>{movie.title}</Text>
            <Text style={styles.description} numberOfLines={3}>{movie.overview}</Text>
          </LinearGradient>
        </ImageBackground>
      </Pressable>
    </View>
  )
}

const CardHeight = 0.3 * getScreenHeight();

const styles = StyleSheet.create({
  card: {
    width: '100%',
    height: CardHeight,
    backgroundColor: "black",
  },
  poster: {
    height: '100%',
    width: "100%",
    resizeMode: "cover",
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'flex-end',
    alignItems: "baseline"
  },
  title: {
    color: '#FFFFFF',
    fontSize: 28,
    fontWeight: "800",
    marginHorizontal: 25,
  },
  description: {
    color: '#FFFFFF',
    fontSize: 14,
    marginHorizontal: 25,
    marginBottom: 20
  },
  counter: {
    position: 'absolute',
    flexDirection: "column",
    right: 16,
    top: 8,
  },
  match: {
    flexDirection: "row",
    marginHorizontal: 25,
    alignItems: "center",
    borderRadius: 6,
    backgroundColor: '#000000aa',
    padding: 8,
    gap: 8,
  },
  matchText: {
    color: Color.textPrimaryColor
  }
});

export default MovieCard;