import { useState, useCallback, useEffect, useRef } from 'react';
import { GroupWithMovies, Movie, database } from '../database';
import MoviesPage from '../common/MoviesPage';
import { ActionBar } from '../common/ActionBar';
import { View, StyleSheet, Alert, Platform } from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import ScreenNavigator from '../navigation';
import { Badge, Button } from 'react-native-elements';
import { GlobalStyle } from '../styles';
import MateListBottomSheet from '../common/MateListBottomSheet';
import { BottomSheet } from '../common/bottomSheet';

function GroupMoviesScreen(params: { route: { params: { id: string }} }) {
  const navigator = useNavigation()
  const sheetRef = useRef<BottomSheet>(null);
  const [group, setGroup] = useState<GroupWithMovies>()
  const [counter, setCounter] = useState<{[k: string]: number}>()

  useEffect(() => {
    database.fetchGroup(params.route.params.id).then(group => {
      if (group) { 
        setGroup(group)
        database.fetchMatesMatchesCount(group.movies.map(m => m.id)).then(result => {
          const dict = Object.fromEntries(result.map(r => [r.watchable_id, r.count]));
          setCounter(dict)
        })
      } else {
        ScreenNavigator.navigateToDiscovery(navigator)
      }
    })
  }, [params])

  useFocusEffect(() => {
    // needed to reopen with close bottom sheet
    sheetRef.current?.close();
  })

  const handleLeaveGroup = useCallback(() => {
    if (Platform.OS != "web") {
      Alert.alert(`Leave group`, `Do you want to leave your group '${group?.group_name}'? Group will still exist.`, [
        {text: 'Cancel', onPress: () => null},
        {text: 'Leave', style: "destructive", onPress: () => {
          database.leaveGroup(params.route.params.id)
            .then(() => navigator.goBack())
        }},
      ]);
    } else {
      database.leaveGroup(params.route.params.id)
        .then(() => navigator.goBack())
    }
  }, [params, group])

  const handleShowMates = useCallback(() => {
    sheetRef.current?.expand();
  }, [sheetRef])

  return (
    <View style={styles.container}>
      <ActionBar title={group?.group_name}>
        <View style={styles.actionBarButtonsContainer}>
          <Button
            icon={{name: GlobalStyle.ICON_MATE_DELETE_FA, type: "font-awesome", color: "white"}}
            onPress={handleLeaveGroup}
            type="clear"
          />
          <View>
            <Button
              icon={{name: GlobalStyle.ICON_GROUP_FA, type: "font-awesome", color: "white"}}
              onPress={handleShowMates}
              type="clear"
            />
            <Badge
              value={group?.group_mates.length}
              badgeStyle={{backgroundColor: "black"}}
              textStyle={{color: "white"}}
              containerStyle={{ position: 'absolute', bottom: 4, right: 4}}
            />
          </View>
        </View>
      </ActionBar>
      {group && <MoviesPage movies={group.movies.map(movie => ({movie: movie, count: counter && counter[movie.id] ? counter[movie.id] : 0, match: true}))}/>}
      {group && <MateListBottomSheet mates={group?.group_mates.map(m => ({ id: m.mate_id, username: m.mate_username }))} sheetRef={sheetRef}/>}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: "100%"
  },
  actionBarButtonsContainer: {
    flex: 1,
    flexDirection: "row-reverse"
  },
});

export default GroupMoviesScreen;