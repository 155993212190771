import { useNavigation } from "@react-navigation/native";
import { useCallback } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { Button, Icon } from "react-native-elements";
import ScreenNavigator from "../navigation";

export function BackButton({style} : {style?: StyleProp<ViewStyle>}) {
    const navigation = useNavigation();
    const goBack = useCallback(() => {
        if (navigation.canGoBack()) {
            navigation.goBack();
        } else {
            // needed when entering details screen without history, eg. via link
            ScreenNavigator.navigateToDiscovery(navigation)
        }
    }, [navigation]);

    return (
        <Button
            icon={{ name: "arrow-back-ios", type: "material", color: "white" }}
            onPress={goBack}
            type="clear"
            style={style} 
        />
    );
  }