import * as React from "react";
import {
    View,
    Text,
    Image,
    StyleSheet,
    Platform
} from "react-native";
import { Header } from "./Header";
import { useCallback, useEffect, useState } from "react";
import { TmdbMovie, TmdbVideo, getMovieVideos } from "../../tmdb";
import { Color } from "../../styles";
import { getScreenWidth } from "../../utils";
import { WebView } from 'react-native-webview';

export function Trailer(params: { movie: TmdbMovie }) {
    const { movie } = params;
    const [video, setVideo] = useState<TmdbVideo>()

    useEffect(() => {
        getMovieVideos(movie.id)
            .then(data => data.filter(video => video.type == 'Trailer')[0])
            .then(data => setVideo(data))
    }, [movie])

    if (!video) {
        return (
            <View style={localStyles.container}>
                <Text>Loading...</Text>
            </View>
        )
    }

    return (
        <View style={localStyles.container}>
            <Header name={"Trailer"}/>
            { Platform.OS === 'web' ? 
                <iframe src={`https://www.youtube.com/embed/${video.key}`} style={localStyles.webView}></iframe>
                :
                <WebView
                    style={localStyles.webView}
                    javaScriptEnabled={true}
                    source={{uri: `https://www.youtube.com/embed/${video.key}`}}
                />
            }
        </View>
    );
}

const localStyles = StyleSheet.create({
    container: {
        width: '100%',
    },
    webView: {
        minHeight: Math.min(270, (getScreenWidth() - 50) * 9 / 16),
        maxHeight: 270,
        minWidth: Math.min(480, getScreenWidth() - 50),
        maxWidth: 480,
        overflow: "hidden",
        alignSelf: "center",
        backgroundColor: Color.backgroundColor,
        borderColor: Color.backgroundColor,
        borderWidth: 0,
    },
    text: {
        color: Color.textSecondaryColor,
        fontSize: 16
    }
});