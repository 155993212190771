import { StyleSheet, Text, View } from 'react-native';
import { supabase } from "../supabase"
import { useCallback, useEffect } from 'react';
import Account from '../auth/Account';
import { useState } from "react";
import { ActionBar } from '../common/ActionBar';
import { Button } from 'react-native-elements';
import { useNavigation } from '@react-navigation/native';
import ScreenNavigator from '../navigation';
import { MovieCountMatch, database } from '../database';
import { Color } from '../styles';
import MoviesPage from '../common/MoviesPage';

const DAYS = 30;
const MOVIES_COUNT = 30;

function PopularScreen() {
  const navigation = useNavigation();
  const [movies, setMovies] = useState<MovieCountMatch[]>([])

  useEffect(() => {
    database.fetchPopularWatchables(DAYS, MOVIES_COUNT).then(setMovies)
  }, [setMovies])

  const openSettings = useCallback(() => {
    ScreenNavigator.navigateToSettingsScreen(navigation)
  }, [navigation])

  return (
    <View style={styles.container} >
      <ActionBar style={styles.actionBar} backButton={false} title={`Popular movies over the last ${DAYS} days`}>
        <Button
          icon={{name: "gear", type: "font-awesome", color: "white"}}
          onPress={openSettings}
          type="clear"
        />
      </ActionBar>
      <MoviesPage movies={movies} showMatch={true}/>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
  },
  actionBar: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingStart: 16
  }
});

export default PopularScreen;