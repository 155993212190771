import { useCallback, useEffect, useState } from "react";
import { SafeAreaView, Text, StyleSheet, View, Pressable } from "react-native";
import { Image, Input } from "react-native-elements";
import { Profile, database } from "../database"
import { useNavigation } from '@react-navigation/native';
import ScreenNavigator from "../navigation";
import { MoviesBackground } from "../common/MoviesBackground";
import { getScreenWidth } from "../utils";
import { Color, GlobalStyle } from "../styles";

// Opened on direct url /invites/b295189d-6dff-4f5e-bcaa-9b34b296e8e4
export default function InvitationSummaryScreen({ route }: { route: { params: { uid: string } } }) {
    const navigation = useNavigation()
    const [tmpMyUsername, setTmpMyUsername] = useState<string>()
    const [myUsername, setMyUsername] = useState<string>()
    const [error, setError] = useState<string>()

    const uid = route.params.uid;

    const [inviterUsername, setInviterUsername] = useState<string>()

    useEffect(() => {
        database.fetchMyProfile().then(data => {
            setMyUsername(data.username)
        })
    }, [])

    useEffect(() => {
        database.fetchProfile(uid)
            .then(data => setInviterUsername(data.username))
    }, [uid]);

    const handleAccept = useCallback(async () => {
        await database.acceptInvite(uid);
        ScreenNavigator.navigateToDiscovery(navigation)
    }, [uid])

    const handleCancel = useCallback(async () => {
        ScreenNavigator.navigateToDiscovery(navigation)
    }, [])

    const handleUpdateUsername = useCallback(async () => {
        setError(undefined)
        if (!tmpMyUsername) {
            setError("Username must be provided.")
            return;
        }
        if (tmpMyUsername.length < 4) {
            setError("Username must be at least 4 character long.")
            return;
        }
        if (!/^([a-zA-Z0-9]{4,})$/.test(tmpMyUsername)) {
            setError("Forbidden character in username. Allowed letters and numbers")
            return;
        }
        database.updateMyUsername(tmpMyUsername)
            .then(profile => setMyUsername(profile.username))
    }, [tmpMyUsername, setMyUsername])

    const updateUsername = () => (
        <View style={styles.buttonsViewContainer}>
            <Text style={[styles.text, styles.info]}>Before accepting invitation, you must set your global username! It will be visible by all your mates.</Text>
            <Input
                inputStyle={styles.input}
                onChangeText={(text) => setTmpMyUsername(text)}
                value={tmpMyUsername}
                placeholder="username"
                autoCapitalize={'none'}
                errorMessage={error}
            />
            <Pressable style={[styles.button, styles.button_accept]} onPress={handleUpdateUsername}>
                <Text style={[styles.button_text, styles.button_accept_text]}>Accept</Text>
            </Pressable>
        </View>
    )

    const buttons = () => (
        <View style={styles.buttonsViewContainer}>
            <Text style={[styles.text, styles.info]}>Explore and discover exciting movie options. Swipe right on interesting stuff and watch it with friends and familly!</Text>
            <View style={styles.buttonContainer}>
                <Pressable style={[styles.button, styles.button_cancel]} onPress={handleCancel}>
                    <Text style={[styles.button_text, styles.button_cancel_text]}>Cancel</Text>
                </Pressable>
                <Pressable style={[styles.button, styles.button_accept]} onPress={handleAccept}>
                    <Text style={[styles.button_text, styles.button_accept_text]}>Accept</Text>
                </Pressable>
            </View>
        </View>
    )

    return (
        <SafeAreaView style={styles.container}>
            <MoviesBackground maxWidth={GlobalStyle.maxWidth}/>
            <View style={styles.formContainer}>
                { myUsername ? 
                <Text style={[styles.text, styles.header]}><Text style={styles.bold}>{myUsername}</Text>, welcome to WatchMate!</Text> : 
                <Text style={[styles.text, styles.header]}>Welcome to WatchMate!</Text> 
                }
                <Text style={[styles.text, styles.header]}><Text style={styles.bold}>{inviterUsername}</Text> want's to be mate with you!</Text>
                <Image style={styles.icon} source={require("../../assets/icon.png")} />
                { myUsername ? buttons() : updateUsername() }
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        height: '100%',
        width: '100%',
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'black'
    },
    formContainer: {
        minWidth: Math.min(500, getScreenWidth() - 100),
        justifyContent: "center",
        alignItems: 'center',
        display: 'flex',
        flexDirection: "column",
        paddingHorizontal: 30,
        gap: 8,
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        borderRadius: 10,
        padding: 20,
        margin: 20
    },
    buttonsViewContainer: {
        justifyContent: "center",
        alignItems: "center",
        gap: 8,
    },
    buttonContainer: {
        flexDirection: "row",
        gap: 16
    },
    button: {
        width: 120,
        height: 44,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 10
    },
    button_text: {
        fontSize: 18
    },
    button_cancel: {
        backgroundColor: Color.BUTTON_SECONDARY_BACKGROUND,
    },
    button_cancel_text: {
        color: Color.BUTTON_CANCEL_TEXT,
    },
    button_accept: {
        backgroundColor: Color.BUTTON_PRIMARY_BACKGROUND,
    },
    button_accept_text: {
        color: Color.BUTTON_PRIMARY_TEXT,
    },
    input: {
        color: Color.textPrimaryColor,
    },
    icon: {
        width: 128,
        height: 128,
        borderRadius: 10
    },
    header: {
        fontSize: 20
    },    
    bold: {
        fontWeight: "bold"
    },
    inviter: {
        fontSize: 20,
        fontWeight: "bold"
    },
    info: {
        fontSize: 15,
    },
    text: {
        color: Color.textPrimaryColor,
        textAlign: "center",
        maxWidth: 400
    }
});