import * as React from "react";
import {
    View,
    Text,
    StyleSheet
} from "react-native";
import { Movie } from "../../database";
import { Color } from "../../styles";

export function Title(params: { movie: Movie }) {
    const { movie } = params
    return (
        <Text style={styles.title}>
            {movie.title}
        </Text>
    );
}

const styles = StyleSheet.create({
    title: {
        fontSize: 36,
        fontWeight: "800",
        textAlign: "center",
        color: Color.textPrimaryColor,
    }
});