import { AnimatedCircularProgress } from 'react-native-circular-progress';
import { StyleProp, Text, View, ViewStyle } from 'react-native';

const Rating = (props: { rating: number, style: StyleProp<ViewStyle>, size: number, width: number }) => {
    const { rating, style, size, width } = props
    return (
        <AnimatedCircularProgress
            style={style}
            size={size || 60}
            width={width || 10}
            rotation={0}
            duration={1000}
            lineCap="round"
            fill={rating * 10}
            tintColor="#2ecc70"
            backgroundColor="rgba(255,255,255,0.2)">
            {fill => <Text style={{ color: "white", fontSize: 18, fontWeight: "800" }}>{((fill) / 10).toFixed(1)}</Text>}
        </AnimatedCircularProgress>
    )
}

export default Rating;