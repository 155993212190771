import * as React from "react";
import {
    View,
    Text,
    Image,
    StyleSheet,
    FlatList
} from "react-native";
import { Header } from "./Header";
import { TMDBApi, TmdbMovie, TmdbWatchProvider, TmdbWatchProviderEntry, getImageUrl, getWatchProviders } from "../../tmdb";
import { useCallback, useEffect, useState } from "react";

export function StreamingList(params: { movie: TmdbMovie }) {
    const { movie } = params;
    const [providers, setProviders] = useState<TmdbWatchProviderEntry[]>([])

    useEffect(() => {
        TMDBApi.getWatchProviders(movie.id)
            // todo dynamic location
            .then(response => response.results["PL"])
            .then(provider => {
                if (provider && provider.flatrate) {
                    setProviders(provider.flatrate)
                } else {
                    setProviders([])
                }
            })
    }, [movie])

    const renderItem = useCallback(({ item }) => (
        <Image
            style={localStyles.icon}
            source={{ uri: getImageUrl(item.logo_path) }} />
    ), [])

    if (providers.length == 0) {
        return <></>
    }

    return (
        <View style={localStyles.container}>
            <Header name={"Streaming now"} />
            <FlatList
                data={providers}
                keyExtractor={provider => provider.provider_id.toString()}
                renderItem={renderItem}
                horizontal={true}
                initialNumToRender={1}
                maxToRenderPerBatch={1}
                updateCellsBatchingPeriod={500}
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                contentContainerStyle={localStyles.innerContainer}
            />
        </View>
    );
}

const localStyles = StyleSheet.create({
    container: {
        width: '100%'
    },
    innerContainer: {
        flexDirection: "row",
        gap: 8
    },
    icon: {
        height: 50,
        width: 50,
        borderRadius: 8
    },
});