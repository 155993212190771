import { StyleSheet, View, Text, Platform } from 'react-native';
import React, { useCallback, useEffect, useState } from "react";
import Movie from './Movie';
import TinderCard from 'react-tinder-card'
import { database } from '../database'
import { getScreenHeight } from '../utils'
import { Color } from '../styles';

function DiscoverScreen() {
  const NUMBER_OF_DISPLAYED_CARDS = 3;
  const PAGE_SIZE = 20;
  const THRESHOLD = 5;
  const [movies, setMovies] = useState([])
  const [noMoreMovies, setNoMoreMovies] = useState(false)
  const [lastDirection, setLastDirection] = useState()
  const [overlayDirectionRight, setOverlayDirectionRight] = useState<boolean | null>(null)

  const addMoviesAtTheEndOfDiscoveryList = useCallback((movies) => {
    setMovies(oldMovies => {
      let currentIds = oldMovies.map(movie => movie.id)
      // filtering, because sometime it fetch suplicates from DB (due to moving offset) - possible sending ids to DB to filter out
      let newMovies = [...movies.filter(movie => !currentIds.includes(movie.id)), ...oldMovies];
      console.log(`Added ${newMovies.length-oldMovies.length} movies. Currently ${newMovies.length} movies.`)
      return newMovies;
    })
  }, [])

  const removeMovieFromDiscoveryList = useCallback((id) => {
    setMovies(oldMovies => {
      let newMovies = oldMovies.filter(movie => movie.id !== id)
      console.log(`Removed 1 movie. Currently ${newMovies.length} movies.`)
      return newMovies;
    })
  }, [])

  const swiped = useCallback(async (direction, movie) => {
    console.log('removing: ' + movie.title + ' to the ' + direction)
    setLastDirection(direction)
    const liked = direction ==='right' ? true : false;
    database.createMatch(movie.id, liked);
  }, [])

  const outOfFrame = useCallback((inMovie) => {
    console.log(inMovie.title + ' left the screen!')
    removeMovieFromDiscoveryList(inMovie.id)
    // reset overlay status
    setOverlayDirectionRight(null)
  }, [])

  const fetchMovies = useCallback(async () => {
    const data = await database.fetchNewWatchables(PAGE_SIZE, THRESHOLD);

    if (data) {
      if (data.length < PAGE_SIZE) {
        console.log("Set no more movies to fetch.")
        setNoMoreMovies(true)
      }
      addMoviesAtTheEndOfDiscoveryList(data)
    }
  }, [setNoMoreMovies])

  useEffect(() => {
    // at the beggining and every time movies list changes, check if need to fetch more movies.
    console.log(`${movies.length} movies left.`)
    if (movies.length < THRESHOLD && !noMoreMovies) {
      console.log(`Less movies=${movies.length} than threshold ${THRESHOLD}`)
      fetchMovies()
    }
  }, [movies, noMoreMovies])

  const calcOverlayStatus = useCallback((direction: string) => {
    switch (direction) {
      case "right": return true;
      case "left": return false;
      default: return null;;
    }
  }, [])

  const overlay = useCallback(() => (
    <View style={[styles.overlay, overlayDirectionRight === true ? styles.overlayRight : styles.overlayLeft]}>
      { overlayDirectionRight === true ?
        <Text style={[styles.overlayText, styles.overlayTextRight]}>Match!</Text> :
        <Text style={[styles.overlayText, styles.overlayTextLeft]}>Nooope!</Text> }
    </View>
  ), [overlayDirectionRight])

  const isCurrentCard = useCallback((index: number) => {
    // card on top has index == 2
    return index == 2;
  }, [])

  return (
    <View style={styles.container}>
      <View style={styles.cardContainer}>
        { movies.slice(movies.length - NUMBER_OF_DISPLAYED_CARDS, movies.length).map((movie, index) =>
            <TinderCard 
              swipeRequirementType="position"
              swipeThreshold={50}
              preventSwipe={["up", "down"]}
              onSwipeRequirementFulfilled={(dir) => Platform.OS != "web" && setOverlayDirectionRight(calcOverlayStatus(dir))}
              onSwipeRequirementUnfulfilled={() => setOverlayDirectionRight(null)}
              key={movie.id} 
              onSwipe={(dir) => swiped(dir, movie)} 
              onCardLeftScreen={() => outOfFrame(movie)} 
              className="pressable">
              <View style={styles.card} pointerEvents={isCurrentCard(index) ? 'auto' : 'none'}>
                <Movie movie={movie}/> 
                { isCurrentCard(index) && overlayDirectionRight !== null && overlay() }
              </View>
            </TinderCard>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
  },
  cardContainer: {
  },
  card: {
    position: 'absolute',
    // backgroundColor: '#fff',
    width: '100%',
    // fix for 100% heigth in 'asbolute' for react-native
    height: getScreenHeight(),
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    marginTop: 50,
  },
  overlayRight: {
    transform: [{ rotate: '-45deg'}],
    flexDirection: "row"
  },
  overlayLeft: {
    transform: [{ rotate: '45deg'}],
    flexDirection: "row-reverse"
  },
  overlayText: {
    fontSize: 50,
    fontWeight: '900',    
    backgroundColor: '#000000aa',
    padding: 8, 
    borderRadius: 6
  },
  overlayTextRight: {
    color: "green"
  },
  overlayTextLeft: {
    color: "red"
  }
});

export default DiscoverScreen;