import * as React from "react";
import {
  ScrollView,
  View,
  StyleSheet
} from "react-native";

import { Button, Text } from 'react-native-elements'
import { Mate, Movie, database } from "../database";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { TMDBApi, TmdbMovie } from "../tmdb";
import { useCallback, useEffect, useRef, useState } from "react";
import { PhotoList } from "./components/PhotoList";
import { RecomendedList } from "./components/RecomendedList";
import { CastList } from "./components/CastList";
import { Trailer } from "./components/Trailer";
import { Plot } from "./components/Plot";
import { FriendsMatch } from "./components/FriendsMatch";
import { MoviePoster } from "./components/MoviePoster";
import { Title } from "./components/Title";
import { MovieDetails } from "./components/MovieDetails";
import { Color, GlobalStyle, GlobalImportStyles } from "../styles";
import { StreamingList } from "./components/StreamingList";
import { ActionBar } from "../common/ActionBar";
import { WatchMateShare } from "../share";
import MateListBottomSheet from "../common/MateListBottomSheet";
import { BottomSheet } from "../common/bottomSheet";

const MovieDetailsScreen = (params: { route: { params: { id: string } } }) => {
  const navigation = useNavigation()
  const { route: { params: { id } } } = params;
  const [dbMovie, setDbMovie] = useState<Movie>()
  const [tmdbMovie, setTmdbMovie] = useState<TmdbMovie>()
  const [mates, setMates] = useState<Mate[]>([])
  const sheetRef = useRef<BottomSheet>(null);
  const scrollViewRef = useRef();

  useEffect(() => {
    database.fetchWatchable(id).then(movie => {
      if (movie) {
        setDbMovie(movie)
        navigation.setOptions({ title: `${movie.title} on WatchMate` })
      }
    })
}, [id, navigation])

  useEffect(() => {
    // TODO tmdb may return no data
    if (dbMovie) {
      TMDBApi.getMovieDetails(dbMovie.tmdb_id).then(data => setTmdbMovie(data))
    }
  }, [dbMovie])

  useFocusEffect(
    useCallback(() => {
      if (scrollViewRef.current) {
        // without that re-entry into screen stays in previous position
        scrollViewRef.current.scrollTo({ x: 0, y: 0, animated: true });
      }
    }, [scrollViewRef, dbMovie])
  );

  const handleShowMates = useCallback((mates: Mate[]) => {
      setMates(mates)
      if (mates.length > 0) {
        sheetRef.current?.expand();
      }
  }, [sheetRef])

  if (tmdbMovie == null) {
    return (
      <View style={styles.container}>
        <ActionBar/>
        <Text>Loading...</Text>
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <ScrollView
        style={styles.content}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.contentScroll}
        ref={scrollViewRef}
      >
        { dbMovie && 
          <MoviePoster key={"poster"} movie={dbMovie}>
            <ActionBar style={styles.actionBar}>
              <Button
                icon={{name: GlobalStyle.ICON_SHARE, color: "white"}}
                onPress={() => WatchMateShare.handleShareMovie(dbMovie)}
                style={styles.shareButton}
                type="clear"
              />
            </ActionBar>
          </MoviePoster> 
        }
        <View key={"data"} style={styles.contentData}>
          { dbMovie && <Title movie={dbMovie}/> }
          { tmdbMovie && <MovieDetails movie={tmdbMovie}/> }
          { dbMovie && <FriendsMatch movie={dbMovie} onPress={handleShowMates}/> }
          { tmdbMovie && <StreamingList movie={tmdbMovie}/> }
          { tmdbMovie && <Plot movie={tmdbMovie}/> }
          { tmdbMovie && <CastList movie={tmdbMovie} maxSize={6} showNames={true} /> }
          { tmdbMovie && <Trailer movie={tmdbMovie} /> }
          { tmdbMovie && <PhotoList movie={tmdbMovie} /> }
          { tmdbMovie && <RecomendedList movie={tmdbMovie} /> }
        </View>
      </ScrollView>
      <MateListBottomSheet mates={mates} sheetRef={sheetRef}/>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flex: 1,
    backgroundColor: Color.backgroundColor,
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    height: '100%',
    width: '100%',
    position: "absolute",
  },
  contentScroll: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  contentData: {
    top: -170,
    alignItems: "center",
    alignSelf: "stretch",
    marginHorizontal: 25,
    gap: 16
  },
  actionBar: {
    justifyContent: 'space-between'
  },
  shareButton: {
    
  }
});

export default MovieDetailsScreen;