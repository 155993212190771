import React from "react";
import { StyleSheet, ImageBackground } from "react-native";
import { getImageUrl } from "../../tmdb";
import { Movie } from "../../database";
import { LinearGradient } from "expo-linear-gradient";
import { GlobalStyle } from "../../styles";

export default function Poster(props: { movie: Movie }) {
    const { movie } = props;
    return (
        <ImageBackground style={styles.poster} source={{ uri: getImageUrl(movie.poster_path) }}>
            <LinearGradient
                colors={['transparent', '#00000000', '#000000']}
                style={styles.poster}>
            </LinearGradient>
        </ImageBackground>
    );
}

const styles = StyleSheet.create({
    poster: {
        width: GlobalStyle.posterWidthCard,
        height: GlobalStyle.posterHeightCard,
    },
});