import { Platform, Share } from "react-native";
import { Movie } from "./database";
import { ScreenNames } from "./navigation";
import { APP_URL } from "@env"
import { supabase } from "./supabase";

export const WatchMateShare = {
    handleShareMovie: async function (movie: Movie) {
        const url = `${getBaseUrl()}/${ScreenNames.MOVIES_DETAILS.renderPath(movie.id)}`;
        await Share.share({
            // title: "WatchMate",
            // message: `See "${movie.title}" on WatchMate!\n${url}`,
            url: url
        })
    },
    handleInvite: async function () {
        const session = await supabase.auth.getSession()
        const requestedUserId = session.data.session.user.id;

        const url = `${getBaseUrl()}/${ScreenNames.INVITE.renderPath(requestedUserId)}`
        await Share.share({
            // title: "WatchMate",
            // message: `Join me on watchmate!\n${url}`,
            url: url
        });
    },
}

const getBaseUrl = () => {
    if (Platform.OS != "web") {
        return APP_URL || "https://watchmate.live";
    }

    const location = window.location.host;

    if (location.includes('localhost')) {
        return `http://${location}`
    }

    return `https://${location}`;
}