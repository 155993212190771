import { useState, useCallback, useEffect } from 'react';
import { Movie, database } from '../database';
import MoviesPage from '../common/MoviesPage';
import { ActionBar } from '../common/ActionBar';
import { View, StyleSheet, Alert, Platform } from 'react-native';
import ScreenNavigator from '../navigation';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { Button } from 'react-native-elements';
import { GlobalStyle } from '../styles';

function MateMoviesScreen(params: { route: { params: { id: string }} }) {
  const navigator = useNavigation()
  const [title, setTitle] = useState<string>()
  const [movies, setMovies] = useState<Movie[]>([])
  const [counter, setCounter] = useState<{[k: string]: number}>()

  useEffect(() => {
    database.fetchMate(params.route.params.id).then(mate => {
      if (mate) {
        setTitle(mate.mate_username)
        if (mate.movies) {
          setMovies(mate.movies)
          database.fetchMatesMatchesCount(mate.movies.map(m => m.id)).then(result => {
            const dict = Object.fromEntries(result.map(r => [r.watchable_id, r.count]));
            setCounter(dict)
          })
        }
      } else {
        ScreenNavigator.navigateToDiscovery(navigator)
      }
    })
  }, [params])

  const handleRemoveMate = useCallback(() => {
    if (Platform.OS != "web") {
      Alert.alert(`Remove mate`, `Do you want to remove you mate '${title}'?`, [
        {text: 'Cancel', onPress: () => null},
        {text: 'Remove', style: "destructive", onPress: () => {
          database.removeMate(params.route.params.id)
            .then(() => navigator.goBack())
        }},
      ]);
    } else {
      database.removeMate(params.route.params.id)
        .then(() => navigator.goBack())
    }
  }, [params, title])

  return (
    <View style={styles.container}>
      <ActionBar title={title}>
        <View style={styles.actionBarButtonsContainer}>
          <Button
            icon={{name: GlobalStyle.ICON_MATE_DELETE_FA, type: "font-awesome", color: "white"}}
            onPress={handleRemoveMate}
            type="clear"
          />
        </View>
      </ActionBar>
      <MoviesPage movies={movies.map(movie => ({movie: movie, count: counter && counter[movie.id] ? counter[movie.id] : 0, match: true}))}/>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: "100%"
  },
  actionBarButtonsContainer: {
    flex: 1,
    flexDirection: "row-reverse"
  },
});

export default MateMoviesScreen;