import { StyleSheet, Text, View, ImageBackground } from 'react-native';
import { TMDBApi, getImageUrl } from "../tmdb"
import { LinearGradient } from "expo-linear-gradient"
import MovieCast from './MovieCast';
import { getScreenWidth } from '../utils';
import React, { useCallback, useEffect, useState } from 'react';
import { TmdbMovie } from '../tmdb'
import Rating from './Rating';
import { BOTTOM_BAR_HEIGHT, BOTTOM_BAR_POSITION } from '../BottomBar';
import { BorderlessButton } from 'react-native-gesture-handler';
import { useNavigation } from '@react-navigation/native';
import ScreenNavigator from '../navigation';
import { GlobalStyle } from '../styles';

function Movie({ movie }) {
  const navigation = useNavigation();
  const [tmdbMovie, setTmdbMovie] = useState<TmdbMovie>(null)
  const [images, setImages] = useState<string[]>([movie.poster_path])
  const [currentImage, setCurrentImage] = useState(0)

  useEffect(() => {
    TMDBApi.getMovieDetails(movie.tmdb_id).then(data => setTmdbMovie(data))
    TMDBApi.getMovieImages(movie.tmdb_id).then(data => {
      if (data && data.backdrops) {
        setImages(oldImages => [...oldImages, ...data.backdrops.slice(0, 4).map(image => image.file_path)]);
      }
    })
  }, [])

  const onLeft = useCallback(() => {
    console.log("clicked left")
    setCurrentImage(currentIndex => {
      const newIndex = Math.max(0, currentIndex - 1);
      console.log(`new index ${newIndex}`)
      return newIndex;
    })
  }, [setCurrentImage, images])

  const onRight = useCallback(() => {
    console.log("clicked right")
    setCurrentImage(currentIndex => {
      const newIndex = Math.min(currentIndex + 1, images.length - 1);
      console.log(`new index ${newIndex}`)
      return newIndex;
    })
  }, [setCurrentImage, images])

  const onDetails = useCallback(() => {
    ScreenNavigator.navigateToMovieDetails(navigation, movie.id)
  }, [navigation, movie])
  
  return (
    <View style={styles.container}>
      <ImageBackground style={styles.poster} source={{ uri: getImageUrl(images[currentImage])}}>
        <LinearGradient 
          colors={['transparent', '#00000000', '#000000']} 
          style={styles.poster}>
          <View style={styles.titleAndRating}>
            <Text style={styles.title} numberOfLines={3}>{movie.title}</Text>
            { tmdbMovie != null && tmdbMovie.vote_average > 0 ? 
              <Rating style={styles.rating} rating={tmdbMovie.vote_average} size={RATING_SIZE} width={10}/>
              : <></> 
            }
          </View>
          <Text style={styles.description} numberOfLines={3}>{movie.overview}</Text>
          <View style={styles.cast}>
            <MovieCast tmdb_id={movie.tmdb_id} />
          </View>
        </LinearGradient>
      </ImageBackground>
      <View style={styles.buttonContainer}>
        <View style={styles.imageButtonContainer}>
          <BorderlessButton style={styles.imageButton} onPress={onLeft}/>
          <BorderlessButton style={styles.imageButton} onPress={onRight}/>
        </View>
        <BorderlessButton style={styles.movieDetailButton} onPress={onDetails}/>
      </View>
    </View>
  )
}

const HORIZONTAL_MARGIN = 25;
const VERTICAL_MARGIN = 10;
const TEXT_COLOR = '#FFFFFF'
const RATING_SIZE = 60;

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    height: '100%', 
    width: '100%', 
    alignContent: 'center', 
    justifyContent: 'center', 
    backgroundColor: 'black' // display black background when loading new image to not show card below
  },
  titleAndRating: {
    width: '100%', 
    display: "flex", 
    flexDirection: "row", 
    paddingHorizontal: HORIZONTAL_MARGIN
  },
  rating: {
    alignSelf: "flex-end",
    marginBottom: HORIZONTAL_MARGIN, 
    marginStart: HORIZONTAL_MARGIN
  },
  buttonContainer: {
    height: '100%', 
    width: '100%', 
    zIndex: 1, // display 'switch' buttons on top of movie card
    position: 'absolute', 
    display: 'flex', 
    flexDirection: "column", 
    justifyContent: 'center', 
    alignItems:'center',
  },
  imageButtonContainer: {
    flexDirection: "row",
    width: "100%",
    height: "50%"
  },
  imageButton: {
    width: '50%', // screen is divided into two button to change background image
    height: '100%',
  },
  movieDetailButton: {
    height: "50%",
    width: "100%"
  },
  poster: {
    width: '100%',
    height: '100%',
    resizeMode: "cover",
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'flex-end',
  },
  title: {
    color: TEXT_COLOR,
    fontSize: 48,
    fontWeight: "800",
    // Make sure that size of title do not push rating out of screen
    // 3 * MARGIN = titleAndRating.paddingHorizontal + rating.marginStart
    maxWidth: Math.min(getScreenWidth(), GlobalStyle.maxWidth) - 3 * HORIZONTAL_MARGIN - RATING_SIZE,
    minWidth: Math.min(getScreenWidth(), GlobalStyle.maxWidth) - 3 * HORIZONTAL_MARGIN - RATING_SIZE
  },
  description: {
    color: TEXT_COLOR,
    fontSize: 16,
    marginHorizontal: HORIZONTAL_MARGIN,
  },
  cast: {
    marginHorizontal: HORIZONTAL_MARGIN,
    marginBottom: BOTTOM_BAR_POSITION + BOTTOM_BAR_HEIGHT + VERTICAL_MARGIN,
    marginTop: VERTICAL_MARGIN
  }
});

export default Movie;